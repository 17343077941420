<template>
	<div class="md-layout">
		<div class="md-layout-item">
			<md-card>
				<md-button
					style="position: absolute; top: 3px; left: 10px; font-size: 30px !important"
					class="md-simple md-just-icon md-round modal-default-button"
					@click="openJobsHelp"
				>
					<md-icon>help</md-icon>
				</md-button>
				<h1 style="display: flex; align-items: center; justify-content: center">Applied Jobs</h1>
				<md-card-header class="md-card-header-icon md-card-header-green">
					<div class="card-icon">
						<md-icon>assignment</md-icon>
					</div>
				</md-card-header>
				<md-card-content>
					<md-table
						:value="queriedData"
						:md-sort.sync="currentSort"
						:md-sort-order.sync="currentSortOrder"
						:md-sort-fn="customSort"
						class="paginated-table table-striped table-hover"
					>
						<md-table-toolbar>
							<md-field>
								<label for="pages">Per page</label>
								<md-select v-model="pagination.perPage" name="pages">
									<md-option v-for="item in pagination.perPageOptions" :key="item" :label="item" :value="item">
										{{ item }}
									</md-option>
								</md-select>
							</md-field>

							<md-field>
								<md-input type="search" class="mb-3" clearable style="width: 200px" placeholder="Search records" v-model="searchQuery"> </md-input>
							</md-field>
						</md-table-toolbar>

						<md-table-row slot="md-table-row" slot-scope="{ item }">
							<md-table-cell v-for="column in columns" :key="column.key" :md-label="column.label" :md-sort-by="column.key">
								{{ item[column.key] }}
							</md-table-cell>
							<md-table-cell>
								<router-link :to="{ name: 'micro-application', params: { id: item.id } }">
									<md-button class="jb-standard">View</md-button>
								</router-link>
							</md-table-cell>
						</md-table-row>
					</md-table>
				</md-card-content>
				<md-card-actions md-alignment="space-between">
					<div class="">
						<p class="card-category">Showing {{ from + 1 }} to {{ to }} of {{ total }} entries</p>
					</div>
					<pagination class="pagination-no-border pagination-success" v-model="pagination.currentPage" :per-page="pagination.perPage" :total="total"> </pagination>
				</md-card-actions>
			</md-card>
		</div>
	</div>
</template>

<script>
import { Pagination } from '@/components';
import { helperArticles } from '@/constants/helper-articles.const';

export default {
	components: {
		Pagination,
	},
	props: {
		data: { type: Array, required: true },
		columns: { type: Array, required: true },
	},
	data() {
		return {
			filterClicked: false,
			currentSort: 'name',
			currentSortOrder: 'asc',
			pagination: {
				perPage: 10,
				currentPage: 1,
				perPageOptions: [5, 10, 15, 20, 25, 50],
				total: 0,
			},
			searchQuery: '',
			newData: null,
		};
	},
	computed: {
		queriedData() {
			let result = this.data;

			if (this.searchQuery.length > 0) {
				result = result.filter(item => {
					return this.columns.some(column => {
						const value = item[column.key]?.toString().toLowerCase() || '';
						return value.includes(this.searchQuery.toLowerCase());
					});
				});
			}

			this.takeResults(result);
			return result.slice(this.from, this.to);
		},
		to() {
			let highBound = this.from + this.pagination.perPage;
			if (this.total < highBound) {
				highBound = this.total;
			}
			return highBound;
		},
		from() {
			return this.pagination.perPage * (this.pagination.currentPage - 1);
		},
		total() {
			return this.newData?.length || 0;
		},
	},
	methods: {
		openJobsHelp() {
			window.open(helperArticles.OPEN_JOBS_HELP, '_blank');
		},
		takeResults(result) {
			this.newData = result;
		},
		handleClick() {
			this.filterClicked = true;
		},
		customSort(value) {
			return value.sort((a, b) => {
				const sortBy = this.currentSort;
				if (this.currentSortOrder === 'desc') {
					return a[sortBy].localeCompare(b[sortBy]);
				}
				return b[sortBy].localeCompare(a[sortBy]);
			});
		},
	},
};
</script>

<style scoped>
.md-table + .md-table {
	margin-top: 16px;
}

.md-table-cell-container {
	display: flex;
	justify-content: flex-start;
}

.md-table-cell-container .md-button {
	width: auto !important;
}

.md-card [class*='md-card-header-'] {
	position: relative !important;
}

.md-button .md-button-content i {
	font-size: 30px !important;
}
</style>
