<template>
	<div class="md-layout">
		<div class="md-layout-item">
			<md-card>
				<h1 style="display: flex; align-items: center; justify-content: center">{{ title }}</h1>

				<md-card-content>
					<md-table v-model="tableData">
						<md-table-row slot="md-table-row" slot-scope="{ item }">
							<md-table-cell v-for="column in columns" :key="column.field" :md-label="column.label" :md-sort-by="column.field">
								<template v-if="column.field !== 'action'">
									{{ item[column.field] }}
								</template>
								<template v-else>
									<router-link :to="getRoute(column.route, item)">
										<md-button class="jb-standard">View</md-button>
									</router-link>
								</template>
							</md-table-cell>
						</md-table-row>
					</md-table>
				</md-card-content>
			</md-card>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		tableData: {
			type: Array,
			required: true,
		},
		columns: {
			type: Array,
			required: true,
		},
		title: {
			type: String,
			required: true,
		},
	},
	methods: {
		getRoute(routeConfig, item) {
			if (!routeConfig) return null;
			// Construct route based on dynamic parameters
			const route = { name: routeConfig.name, params: {} };
			for (const [paramKey, itemField] of Object.entries(routeConfig.params)) {
				route.params[paramKey] = item[itemField];
			}
			return route;
		},
	},
};
</script>

<style scoped>
.md-table + .md-table {
	margin-top: 16px;
}

.md-table-cell-container {
	display: flex;
	justify-content: flex-start;
}

.md-table-cell-container .md-button {
	width: auto !important;
}

.md-card [class*='md-card-header-'] {
	position: relative !important;
}

.md-button .md-button-content i {
	font-size: 30px !important;
}
</style>
