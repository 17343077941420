<template>
	<div v-if="bursary && bursary.additionalSubmissions" class="video-submission-content-container">
		<div v-if="loading" class="background">
			<div v-if="loading" class="logo-position lds-circle">
				<div><img src="@/assets/img/logo.png" /></div>
			</div>
		</div>
		<div class="written-submission-container">
			<!-- Left side: Instructions and Question -->
			<div class="submission-instructions">
				<h3 style="text-align: left">Written Submission Instructions</h3>
				<p class="text-center" v-if="bursary.additionalSubmissions.writtenSubmission === 'No' || bursary.additionalSubmissions.writtenSubmission === ''">
					There are no written submissions required.
				</p>
				<p style="text-align: left" v-if="bursary.additionalSubmissions.writtenSubmission === 'Yes'">Instructions: Please submit your written response as a PDF file.</p>
				<br />
				<h3 style="text-align: left" v-if="bursary.additionalSubmissions.writtenSubmission === 'Yes'">Question</h3>
				<h4
					v-if="bursary.additionalSubmissions.writtenSubmission === 'Yes'"
					style="margin-top: -1rem; text-align: left"
					v-html="bursary.additionalSubmissions.writtenSubmissionDetails"
				></h4>
			</div>

			<!-- Right side: File input for submission -->
			<div class="submission-input" v-if="bursary.additionalSubmissions.writtenSubmission === 'Yes'">
				<md-field class="md-success">
					<label>Written Submission:</label>
					<md-file @change="onFileSelected" accept="application/pdf" />
				</md-field>
			</div>
		</div>

		<!-- Success Modal -->
		<modal v-show="showSuccessModal">
			<template slot="header">
				<div style="font-size: 2rem">Success! 🎊</div>
			</template>
			<template slot="body">
				<p>Written Submission Successfully Submitted! ✅</p>
			</template>
			<template slot="footer">
				<md-button class="md-button md-success" @click="closeModal"> Okay</md-button>
			</template>
		</modal>

		<!-- Error Modal -->
		<modal v-if="showErrorModal">
			<template #header>
				<h4 class="modal-title black">Whoa there! 🤚</h4>
			</template>
			<template #body>
				<h4>Please add a written submission! ⛔️</h4>
			</template>
			<template #footer>
				<md-button class="md-success" @click="closeErrorModal"> Ok </md-button>
			</template>
		</modal>
	</div>
</template>

<script>
import { doc, updateDoc, getDoc, query, where, getDocs, collection } from 'firebase/firestore';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { db } from '@/firebase/init';
import firebase from 'firebase/compat/app';
import Modal from '@/components/Modal';

export default {
	components: {
		Modal,
	},
	data() {
		return {
			studentAlias: '',
			bursaryID: this.$route.params.id,
			writtenSubmissionFile: null,
			writtenSubmissionLink: '',
			bursary: {},
			loading: false,
			isFileUploaded: false,
			showSuccessModal: false,
			writtenSubmitted: false,
			showErrorModal: false,
		};
	},
	methods: {
		closeErrorModal() {
			this.showErrorModal = false;
		},
		closeModal() {
			this.showSuccessModal = false;
			this.modalResolve(true);
		},
		onFileSelected(event) {
			this.writtenSubmissionFile = event.target.files[0];
		},

		async validate() {
			if (this.bursary.additionalSubmissions && this.bursary.additionalSubmissions.writtenSubmission === 'Yes') {
				if (!this.writtenSubmitted) {
					await this.submitWrittenSubmission();
					if (!this.writtenSubmitted) {
						return false;
					}
				}
			}
			return true;
		},

		async submitWrittenSubmission() {
			try {
				if (!this.writtenSubmissionFile) {
					this.showErrorModal = true;
					return false;
				}

				this.loading = true;

				const storage = getStorage();
				const filePath = `users/students/${this.studentAlias}/bursaryDocuments/${this.bursaryID}/additionalSubmissions/writtenSubmissions/${this.writtenSubmissionFile.name}`;
				const fileRef = ref(storage, filePath);

				// Upload the file
				await uploadBytes(fileRef, this.writtenSubmissionFile);

				// Get the download URL for the uploaded file
				const downloadURL = await getDownloadURL(fileRef);

				// Update Firestore with the file's download URL
				const bursaryRef = doc(db, 'bursary-applications', `${this.studentAlias}-${this.bursaryID}`);
				await updateDoc(bursaryRef, {
					'additionalSubmissions.writtenSubmissionLink': downloadURL,
				});
				this.$emit('writtenSubmission', downloadURL);
				this.writtenSubmitted = true;
				this.showSuccessModal = true;

				this.loading = false;
				return new Promise(resolve => {
					this.modalResolve = resolve;
				});
			} catch (error) {
				this.writtenSubmitted = false;
				this.loading = false;
				this.showErrorModal = true;
			}
		},

		async fetchStudentAlias() {
			try {
				const currentUser = firebase.auth().currentUser;
				if (!currentUser) {
					return;
				}

				const usersCollection = collection(db, 'users');
				const q = query(usersCollection, where('userId', '==', currentUser.uid));

				const querySnapshot = await getDocs(q);

				if (!querySnapshot.empty) {
					querySnapshot.forEach(docSnap => {
						this.studentAlias = docSnap.id;
					});
				}
			} catch (error) {
				console.error('Error fetching student alias:', error);
			}
		},

		async fetchBursaryDetails() {
			try {
				const bursaryRef = doc(db, 'bursaries', this.bursaryID);
				const bursarySnap = await getDoc(bursaryRef);

				if (bursarySnap.exists()) {
					this.bursary = bursarySnap.data();
				}
			} catch (error) {
				console.error('Error fetching bursary details:', error);
			}
		},
	},

	mounted() {
		this.fetchStudentAlias();
		this.fetchBursaryDetails();
	},
};
</script>

<style scoped>
.video-submission-content-container {
	width: 90%;

	margin: auto;
	padding: 10px;
	box-sizing: border-box;
}

.written-submission-container {
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	gap: 20px;
	flex-wrap: wrap;
}

.submission-instructions {
	flex: 1 1 45%;
}

.submission-input {
	flex: 1 1 45%;
}

.md-file input {
	width: 100%;
}

.background {
	width: 100vw;
	height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
}

.background .logo-position {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.text-center {
	text-align: center;
}

/* Responsive adjustments for smaller screens */
@media (max-width: 768px) {
	.video-submission-content-container {
		width: 95%;
		padding: 15px;
	}

	.written-submission-container {
		flex-direction: column;
	}

	.submission-instructions,
	.submission-input {
		width: 100%;
	}

	h3,
	h4 {
		font-size: 1.1rem;
		text-align: center;
	}

	.background .logo-position {
		top: 45%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
}

@media (max-width: 480px) {
	h3,
	h4 {
		font-size: 1rem;
		margin-top: 0.5rem;
		text-align: center;
	}

	.video-submission-content-container {
		width: 100%;
		padding: 5px;
	}

	.md-field label,
	.md-file input {
		font-size: 0.9rem;
	}
}
</style>
