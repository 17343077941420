// Client and Student Dashboard Components
import DashboardLayout from '@/components/Layout/DashboardLayout.vue';
import Support from '@/views/dashboard/support/Support.vue';
import Feedback from '@/views/dashboard/feedback/Feedback.vue';
import Settings from '@/views/dashboard/settings/Settings.vue';

// Client Dashboard Components
import ClientDashboard from '@/views/dashboard/client/ClientDashboard.vue';
import ClientProfile from '@/views/dashboard/client/profile/Profile.vue';
import EditClientProfile from '@/views/dashboard/client/profile/EditProfile.vue';

// Post Jobs
import PostMicro from '@/views/dashboard/client/jobs/post/micro/PostJob.vue';
import PostPartTime from '@/views/dashboard/client/jobs/post/part-time/PostJob.vue';
import BursaryWizard from '@/views/dashboard/client/bursaries/post/BursaryWizard.vue'; // Add this import

// Edit Job
import EditMicro from '@/views/dashboard/client/jobs/edit/micro/EditJob.vue';

// Cancelled Job
import Cancel from '@/views/dashboard/client/jobs/cancel/Cancel.vue';

// Dissatisfied Job
import DissatisfiedJob from '@/views/dashboard/client/jobs/dissatisfied/Dissatisfied.vue';

// Client Job
import PendingJob from '@/views/dashboard/client/jobs/pending/PendingJob.vue';
import ActiveJob from '@/views/dashboard/client/jobs/active/ActiveJob.vue';
import CompleteJob from '@/views/dashboard/client/jobs/complete/CompleteJob.vue';
import IncompleteJob from '@/views/dashboard/client/jobs/incomplete/IncompleteJob';
import ApplicationStatus from '@/views/dashboard/client/jobs/applications/applicants/ApplicationStatus.vue';

// Job Statuses
import MicroStatus from '@/views/dashboard/client/jobs/status/micro/Status.vue';

import StudentProfile from '@/views/dashboard/student/profile/Profile.vue';

import PaymentSuccess from '@/views/dashboard/client/jobs/payment/success/Success.vue';

import PaymentFail from '@/views/dashboard/client/jobs/payment/fail/Fail.vue';
import Users from '@/views/dashboard/client/Users/Users.vue';

// Bursaries
import Bursaries from '@/views/dashboard/client/bursaries/active/Bursaries.vue';
import BursaryApplications from '@/views/dashboard/client/bursaries/applications/BursaryApplications.vue';

export default {
	path: '/client',
	component: DashboardLayout,
	name: 'client-nav-menu',
	redirect: '/client/jobs/micro/post',
	children: [
		{
			path: 'dashboard',
			name: 'client-dashboard',
			components: { default: ClientDashboard },
			meta: {
				requiresAuth: true,
				userRole: 'client',
				emailVerified: true,
			},
		},
		{
			path: 'bursaries',
			name: 'bursries',
			components: { default: Bursaries },
			meta: {
				requiresAuth: true,
				userRole: 'client',
				emailVerified: true,
			},
		},
		{
			path: 'bursaries/applications/:bursaryId',
			name: 'bursary-applications',
			components: { default: BursaryApplications },
			meta: {
				requiresAuth: true,
				userRole: 'client',
				emailVerified: true,
			},
		},
		{
			path: 'payment/success/:id',
			name: 'payment-success',
			components: { default: PaymentSuccess },
			meta: {
				requiresAuth: true,
				userRole: 'client',
				emailVerified: true,
			},
		},
		{
			path: 'payment/fail/:id',
			name: 'payment-failed',
			components: { default: PaymentFail },
			meta: {
				requiresAuth: true,
				userRole: 'client',
				emailVerified: true,
			},
		},
		{
			path: 'jobs/micro/post',
			name: 'post-a-job',
			components: { default: PostMicro },
			meta: {
				requiresAuth: true,
				userRole: 'client',
				emailVerified: true,
				jobType: 'micro',
			},
		},
		{
			path: 'jobs/part-time/post',
			name: 'post-a-part-time-job',
			components: { default: PostPartTime },
			meta: {
				requiresAuth: true,
				userRole: 'client',
				emailVerified: true,
				jobType: 'part-time',
			},
		},
		{
			path: 'bursary/post',
			name: 'post-a-bursary',
			components: { default: BursaryWizard },
			meta: {
				requiresAuth: true,
				userRole: 'client',
				emailVerified: true,
			},
		},
		{
			path: 'jobs/micro/edit/:id',
			name: 'edit-micro-job',
			components: { default: EditMicro },
			meta: {
				requiresAuth: true,
				userRole: 'client',
				emailVerified: true,
			},
		},
		{
			path: 'jobs/active',
			name: 'active-jobs',
			components: { default: ActiveJob },
			meta: {
				requiresAuth: true,
				userRole: 'client',
				emailVerified: true,
			},
		},
		{
			path: 'jobs/active/applicants/:id',
			name: 'client-micro-status',
			components: { default: ApplicationStatus },
			meta: {
				requiresAuth: true,
				userRole: 'client',
				emailVerified: true,
				jobType: 'micro',
			},
		},
		{
			path: 'jobs/pending',
			name: 'pending-jobs',
			components: { default: PendingJob },
			meta: {
				requiresAuth: true,
				userRole: 'client',
				emailVerified: true,
			},
		},
		{
			path: 'jobs/complete',
			name: 'complete-jobs',
			components: { default: CompleteJob },
			meta: {
				requiresAuth: true,
				userRole: 'client',
				emailVerified: true,
			},
		},
		{
			path: 'jobs/incomplete',
			name: 'incomplete-jobs',
			components: { default: IncompleteJob },
			meta: {
				requiresAuth: true,
				userRole: 'client',
				emailVerified: true,
			},
		},
		{
			path: 'jobs/dissatisfied',
			name: 'dissatisfied-jobs',
			component: DissatisfiedJob,
			meta: {
				requiresAuth: true,
				userRole: 'client',
				emailVerified: true,
			},
		},
		{
			path: 'support',
			name: 'get-support',
			component: Support,
			meta: {
				requiresAuth: true,
				userRole: 'client',
				emailVerified: true,
			},
		},
		{
			path: 'feedback',
			name: 'give-feedback',
			component: Feedback,
			meta: {
				requiresAuth: true,
				userRole: 'client',
				emailVerified: true,
			},
		},
		{
			path: 'settings',
			name: 'settings',
			component: Settings,
			meta: {
				requiresAuth: true,
				userRole: 'client',
				emailVerified: true,
			},
		},
		{
			path: 'profile/edit/:id',
			name: 'edit-client-profile',
			component: EditClientProfile,
			meta: {
				requiresAuth: true,
				userRole: 'client',
				emailVerified: true,
			},
		},
		{
			path: 'profile/:id',
			name: 'client-profile',
			component: ClientProfile,
			meta: {
				requiresAuth: true,
				emailVerified: true,
				userRole: 'client',
				client: true,
			},
		},
		{
			path: 'profile/:id',
			name: 'view-student-profile',
			component: StudentProfile,
			meta: {
				requiresAuth: true,
				userRole: 'client',
				emailVerified: true,
			},
		},
		{
			path: 'cancel/:id',
			name: 'client-cancel',
			component: Cancel,
			meta: {
				requiresAuth: true,
				userRole: 'client',
				emailVerified: true,
			},
		},
		{
			path: 'users',
			name: 'client-users',
			component: Users,
			meta: {
				requiresAuth: true,
				userRole: 'client',
				emailVerified: true,
			},
		},
	],
};
