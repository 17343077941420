<template>
	<div class="bursary-container">
		<div v-if="loading" class="background">
			<div v-if="loading" class="logo-position lds-circle">
				<div><img src="@/assets/img/logo.png" /></div>
			</div>
		</div>
		<div v-if="bursary && employer" class="bursary-content-container">
			<md-card>
				<md-card-header>
					<h1 class="info-text centre">{{ bursary.title }}</h1>
					<div class="w-full flex items-center justify-center">
						<div class="row">
							<md-icon>business </md-icon>
							<p class="margin-left-small">{{ employer.companyName }}</p>
						</div>
						<div class="row margin-left-large">
							<md-icon>calendar_month </md-icon>
							<p class="margin-left-small">{{ bursary.applicationDeadline }}</p>
						</div>
					</div>

					<div v-if="bursaryApplication && bursaryApplication.applicationStatus === BursaryStatuses.APPLIED">
						<p style="color: green; text-align: center; font-size: 2rem">🎉🎉🎉</p>
						<p style="color: green; text-align: center; font-size: 2rem">You have already applied for this bursary</p>
					</div>
				</md-card-header>
				<md-card-content>
					<collapse
						:collapse="
							bursary.workPlacement === 'Yes'
								? ['Bursary Description', 'Eligibility Requirements', 'Funding Details', 'Work Placement Information']
								: ['Bursary Description', 'Eligibility Requirements', 'Funding Details']
						"
						icon="keyboard_arrow_down"
						color-collapse="success"
					>
						<template slot="md-collapse-pane-1">
							<md-card class="bg-success">
								<md-card-content>
									<div class="row">
										<md-icon class="bursary-icon">business </md-icon>
										<h4 class="margin-left-small">Company Name</h4>
									</div>
									<p class="margin-left-medium margin-top-small">{{ employer.companyName }}</p>
									<div class="row">
										<md-icon class="bursary-icon">school </md-icon>
										<h4 class="margin-left-small">Bursary Name</h4>
									</div>
									<p class="margin-left-medium margin-top-small">{{ bursary.title }}</p>

									<div class="row">
										<md-icon class="bursary-icon">description </md-icon>
										<h4 class="margin-left-small">Bursary Description</h4>
									</div>
									<div class="margin-left-medium margin-top-small" v-html="bursary.description"></div>
									<div class="row">
										<md-icon class="bursary-icon">checklist</md-icon>
										<h4 class="margin-left-small">Eligibility Requirements</h4>
									</div>
									<div class="margin-left-medium margin-top-small" v-html="bursary.bursaryRequirements"></div>

									<div class="row">
										<md-icon class="bursary-icon">folder</md-icon>
										<h4 class="margin-left-small">Required Documents</h4>
									</div>
									<ul class="margin-left-small margin-top-small">
										<li v-for="(document, index) in hasDocuments()" :key="index">{{ formatDocumentName(document) }}</li>
									</ul>
									<div class="row" v-if="bursary && bursary.additionalDocuments.length > 0">
										<md-icon class="bursary-icon">folder</md-icon>
										<h4 class="margin-left-small">Additional Documents</h4>
									</div>
									<ul class="margin-left-small margin-top-small">
										<li v-for="(document, index) in bursary.additionalDocuments" :key="index">{{ document.name || document }}</li>
									</ul>
									<br />
									<div class="row">
										<md-icon class="bursary-icon">info</md-icon>
										<h4 class="margin-left-small">Document Submission Instructions</h4>
									</div>
									<div class="margin-left-medium margin-top-small" v-html="bursary.documentSubmissionInstructions"></div>
									<div class="row">
										<md-icon class="bursary-icon">school</md-icon>
										<h4 class="margin-left-small">Funding Cover</h4>
									</div>
									<ul style="margin-top: -1rem">
										<li v-for="(value, key) in bursary.fundingCover" :key="key">{{ formatKey(key) }}: {{ value ? '✅' : '❌' }}</li>
									</ul>
									<div class="row">
										<md-icon class="bursary-icon">description</md-icon>
										<h4 class="margin-left-small">Additional Cover Details</h4>
									</div>
									<div class="margin-left-medium margin-top-small" v-html="bursary.additionalCoverDetails"></div>
									<div class="row">
										<md-icon class="bursary-icon">place</md-icon>
										<h4 class="margin-left-small">Work Placement Information</h4>
									</div>
									<div class="margin-left-medium margin-top-small" v-html="bursary.workPlacementInformation"></div>
									<div class="row" v-show="bursary.additionalSubmissions && bursary.additionalSubmissions.videoSubmission === 'Yes'">
										<md-icon class="bursary-icon">videocam</md-icon>
										<h4 class="margin-left-small">Video Submission Details</h4>
									</div>
									<div
										class="margin-left-medium margin-top-small"
										v-show="bursary.additionalSubmissions && bursary.additionalSubmissions.videoSubmission === 'Yes'"
										v-html="bursary.additionalSubmissions.videoSubmissionDetails"
									></div>
									<div class="row" v-show="bursary.additionalSubmissions && bursary.additionalSubmissions.writtenSubmission === 'Yes'">
										<md-icon class="bursary-icon">assignment</md-icon>
										<h4 class="margin-left-small">Written Submission Details</h4>
									</div>
									<div
										class="margin-left-medium margin-top-small"
										v-show="bursary.additionalSubmissions && bursary.additionalSubmissions.writtenSubmission === 'Yes'"
										v-html="bursary.additionalSubmissions.writtenSubmissionDetails"
									></div>
								</md-card-content>
							</md-card>
						</template>
					</collapse>
				</md-card-content>
				<div v-if="!bursaryApplication || bursaryApplication.applicationStatus === BursaryStatuses.IN_PROGRESS" class="apply-button-container">
					<md-button @click="applyForBursary" class="jb-standard">Apply</md-button>
				</div>
			</md-card>
		</div>

		<modal v-if="error">
			<template #header>
				<h4 class="modal-title black">Apologies 😔</h4>
			</template>
			<template #body>
				<h4>{{ errorMessage }}</h4>
			</template>
			<template #footer>
				<md-button class="md-success" @click="closeErrorModal">Ok</md-button>
			</template>
		</modal>
	</div>
</template>

<script>
import db from '@/firebase/init';
import Modal from '@/components/Modal.vue';
import { Collapse } from '@/components';
import firebase from 'firebase/compat/app';
import { BursaryStatuses } from '../../../../constants/bursary-statuses.const';
import { collection, doc, getDoc, getDocs, query, where } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
export default {
	components: {
		Modal,
		Collapse,
	},
	data() {
		return {
			bursaryId: null,
			bursary: null,
			employer: null,
			bursaryApplication: null,
			loading: false,
			error: false,
			errorMessage: '',
			BursaryStatuses,
		};
	},
	computed: {
		filteredFundingCover() {
			if (!this.bursary || !this.bursary.fundingCover) {
				return [];
			}
			return Object.keys(this.bursary.fundingCover)
				.filter(key => this.bursary.fundingCover[key])
				.map(key => key.charAt(0).toUpperCase() + key.slice(1));
		},
	},
	methods: {
		formatKey(key) {
			return key.charAt(0).toUpperCase() + key.slice(1).replace(/_/g, ' ');
		},
		applyForBursary() {
			this.$router.push({ name: 'bursary-application-wizard', params: { id: this.bursaryId } });
		},
		hasDocuments() {
			return Object.keys(this.bursary.documentationRequirements).filter(doc => this.bursary.documentationRequirements[doc]);
		},
		formatDocumentName(document) {
			return document.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase());
		},
		async getBursaryInfo() {
			this.loading = true;
			try {
				const bursaryRef = doc(db, 'bursaries', this.bursaryId);
				const bursaryDoc = await getDoc(bursaryRef);
				if (bursaryDoc.exists()) {
					this.bursary = bursaryDoc.data();

					if (this.bursary.employerId) {
						await this.getCompanyInfo(this.bursary.employerId);
					}
				}
			} catch (error) {
				this.handleError("We've encountered an issue loading the bursary information. Please contact Jobox support for assistance");
			} finally {
				this.loading = false;
			}
		},
		async getCompanyInfo(employerId) {
			this.loading = true;
			try {
				const clientQuery = query(collection(db, 'clients'), where('userId', '==', employerId));
				const clientQuerySnapshot = await getDocs(clientQuery);
				if (!clientQuerySnapshot.empty) {
					this.employer = clientQuerySnapshot.docs[0].data();
				}
			} catch (error) {
				this.handleError("We've encountered an issue loading the employer information. Please contact Jobox support for assistance");
			} finally {
				this.loading = false;
			}
		},
		async getBursaryApplication() {
			this.loading = true;
			try {
				const currentUser = getAuth().currentUser;
				if (!currentUser) return;

				// Retrieve studentAlias
				const usersQuery = query(collection(db, 'users'), where('userId', '==', currentUser.uid));
				const querySnapshot = await getDocs(usersQuery);

				let studentAlias = '';
				if (!querySnapshot.empty) {
					studentAlias = querySnapshot.docs[0].id;
				}

				if (!studentAlias) throw new Error('Student alias not found.');

				// Construct doc ID for bursary application
				const applicationId = `${studentAlias}-${this.bursaryId}`;
				const applicationRef = doc(db, 'bursary-applications', applicationId);
				const applicationSnap = await getDoc(applicationRef);

				if (applicationSnap.exists()) {
					this.bursaryApplication = applicationSnap.data();
				}
			} catch (error) {
				this.handleError('Error fetching bursary application. Please contact support.');
			} finally {
				this.loading = false;
			}
		},
		handleError(message) {
			this.error = true;
			this.errorMessage = message;
		},
		closeErrorModal() {
			this.error = false;
		},
	},
	created() {
		this.bursaryId = this.$route.params.id;
		this.getBursaryInfo();
		this.getBursaryApplication();
	},
};
</script>

<style scoped>
.bursary-icon {
	font-size: 1.5rem !important;
}

.md-icon {
	align-items: flex-start;
}

.apply-button-container {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	padding: 1rem;
}

.bursary-container {
	width: 96.5vw;
}

.bursary-container .bursary-content-container {
	max-width: 1200px;
	margin: auto;
}

.background {
	width: 100vw !important;
	height: 100vh !important;
	display: flex !important;
	align-items: center !important;
	justify-content: center !important;
}

.background .logo-position {
	position: absolute;
	top: 40%;
	left: 47%;
	transform: translate(-50%, -50%);
}

.margin-left-small {
	margin-left: 0.5rem;
}

.margin-left-medium {
	margin-left: 2.2rem;
}

.margin-left-large {
	margin-left: 2rem;
}

.margin-top-small {
	margin-top: -1.3rem;
}

.centre {
	text-align: center;
}

.row {
	display: flex;
	align-items: center;
}
</style>
