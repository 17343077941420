<template>
	<div v-if="bursary && bursary.additionalSubmissions" class="video-submission-content-container">
		<div v-if="loading" class="background">
			<div v-if="loading" class="logo-position lds-circle">
				<div><img src="@/assets/img/logo.png" /></div>
			</div>
		</div>

		<div class="content-container">
			<!-- Left side: Instructions and Question -->
			<div class="instructions-container">
				<h3 class="">Video Submission Instructions</h3>
				<p v-if="bursary.additionalSubmissions.videoSubmission === 'No' || bursary.additionalSubmissions.videoSubmission === ''">
					There are no video submissions required.
				</p>
				<p v-if="bursary.additionalSubmissions.videoSubmission === 'Yes'">Instructions: Please record and upload a video or provide a link to a pre-recorded video.</p>

				<h3 v-if="bursary.additionalSubmissions.videoSubmission === 'Yes'" class="question-title">Question</h3>
				<h4 v-if="bursary.additionalSubmissions.videoSubmission === 'Yes'" class="question-text" v-html="bursary.additionalSubmissions.videoSubmissionDetails"></h4>
			</div>

			<!-- Right side: Video upload and Loom button -->
			<div v-if="bursary.additionalSubmissions.videoSubmission === 'Yes'" class="upload-container">
				<button ref="loomButton" class="loom-record-button">Record with Loom</button>
				<p class="or-text">Or</p>
				<md-field :class="{ 'md-valid': videoLink.trim().length > 0 }">
					<label>Provide Link</label>
					<md-input v-model="videoLink"></md-input>
					<md-icon>add_link</md-icon>
				</md-field>
			</div>
		</div>

		<!-- Success Modal -->
		<modal v-if="showSuccessModal">
			<template slot="header">
				<div style="font-size: 2rem">Success! 🎊</div>
			</template>
			<template slot="body">
				<p>Video Submitted Successfully! ✅</p>
			</template>
			<template slot="footer">
				<md-button class="md-button md-success" @click="closeModal"> Okay</md-button>
			</template>
		</modal>

		<!-- Error Modal -->
		<modal v-if="showErrorModal">
			<template #header>
				<h4 class="modal-title black">Whoa there! 🤚</h4>
			</template>
			<template #body>
				<h4>Please add a video submission! ⛔️</h4>
			</template>
			<template #footer>
				<md-button class="md-success" @click="closeErrorModal"> Ok </md-button>
			</template>
		</modal>
	</div>
</template>

<script>
import { doc, updateDoc, collection, query, where, getDocs, getDoc } from 'firebase/firestore';
import { db } from '@/firebase/init';
import { isSupported, setup } from '@loomhq/record-sdk';
import firebase from 'firebase/compat/app';
import Modal from '@/components/Modal';
export default {
	components: {
		Modal,
	},
	data() {
		return {
			studentAlias: '',
			bursaryID: this.$route.params.id,
			videoFile: null,
			videoLink: '',
			bursary: {},
			loading: false,
			videoSubmitted: false,
			showSuccessModal: false,
			showErrorModal: false,
		};
	},
	methods: {
		closeErrorModal() {
			this.showErrorModal = false;
		},
		closeModal() {
			this.showSuccessModal = false;
			this.modalResolve(true);
		},
		async validate() {
			if (this.bursary.additionalSubmissions && this.bursary.additionalSubmissions.videoSubmission === 'Yes') {
				if (!this.videoSubmitted) {
					await this.submitVideo();
					if (!this.videoSubmitted) {
						return false;
					}
				}
			}
			return true;
		},

		async getLoomToken() {
			try {
				const generateToken = firebase.functions().httpsCallable('getLoomToken');
				const result = await generateToken();
				return {
					publicId: result.data.publicId,
					token: result.data.token,
				};
			} catch (error) {
				console.error('Error fetching Loom token:', error);
				return null;
			}
		},

		async initializeLoom() {
			try {
				this.loading = true;
				const { supported } = await isSupported();
				if (!supported) {
					alert('Loom is not supported in this browser.');
					this.loading = false;
					return;
				}

				const loomToken = await this.getLoomToken();
				if (!loomToken || !loomToken.publicId || !loomToken.token) {
					alert('Failed to retrieve Loom token. Please try again.');
					this.loading = false;
					return;
				}

				const button = this.$refs.loomButton;
				const { configureButton } = await setup({
					publicAppId: loomToken.publicId,
					jws: loomToken.token,
				});

				const sdkButton = configureButton({ element: button });

				sdkButton.on('insert-click', async video => {
					this.videoLink = video.embedUrl;
				});

				this.loading = false;
			} catch (error) {
				this.loading = false;
			}
		},

		async submitVideo() {
			try {
				if (!this.videoLink || this.videoLink.trim().length === 0) {
					this.showErrorModal = true;
					return false;
				}
				if (this.videoLink && this.videoLink.trim().length > 0) {
					const bursaryRef = doc(db, 'bursary-applications', `${this.studentAlias}-${this.bursaryID}`);

					await updateDoc(bursaryRef, {
						'additionalSubmissions.videoSubmissionLink': this.videoLink,
					});
					this.$emit('videoSubmission', this.videoLink);
					this.videoSubmitted = true;
					this.showSuccessModal = true;
					return new Promise(resolve => {
						this.modalResolve = resolve;
					});
				}
			} catch (error) {
				this.videoSubmitted = false;
				this.showErrorModal = true;
				alert('Video submission failed. Please try again.');
			}
		},

		async fetchBursaryDetails() {
			try {
				const currentUser = firebase.auth().currentUser;
				if (!currentUser) {
					return;
				}

				const usersCollection = collection(db, 'users');
				const q = query(usersCollection, where('userId', '==', currentUser.uid));

				const querySnapshot = await getDocs(q);
				if (!querySnapshot.empty) {
					querySnapshot.forEach(docSnap => {
						this.studentAlias = docSnap.id;
					});
				}

				const bursaryRef = doc(db, 'bursaries', this.bursaryID);
				const bursarySnap = await getDoc(bursaryRef);

				if (bursarySnap.exists()) {
					this.bursary = {
						...bursarySnap.data(),
						additionalSubmissions: bursarySnap.data().additionalSubmissions || {},
					};
				}
			} catch (error) {
				console.error('Error fetching bursary details:', error);
			}
		},
	},

	mounted() {
		this.fetchBursaryDetails();
		this.initializeLoom();
	},
};
</script>

<style scoped>
.video-submission-content-container {
	width: 90%;

	margin: auto;
	padding: 10px;
	box-sizing: border-box;
}

.content-container {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	gap: 20px;
}

.instructions-container {
	flex: 1 1 50%;
}

.upload-container {
	flex: 1 1 40%;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.loom-record-button {
	background-color: #5c99ee;
	color: white;
	padding: 10px 15px;
	border: none;
	border-radius: 5px;
	cursor: pointer;
	margin-bottom: 10px;
	width: 100%;
	margin-top: 2rem;
}

.or-text {
	text-align: center;
	margin: 10px 0;
}

.background {
	width: 100vw;
	height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
}

.background .logo-position {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.text-center {
	text-align: center;
}

.question-title {
	margin-top: 20px;
	font-weight: bold;
}

.question-text {
	margin-top: -1rem;
}

@media (max-width: 768px) {
	.video-submission-content-container {
		width: 95%;
	}

	.content-container {
		flex-direction: column;
	}

	h3,
	h4 {
		font-size: 1.1rem;
		text-align: center;
	}

	.loom-record-button {
		font-size: 0.9rem;
		padding: 8px 12px;
	}

	.background .logo-position {
		top: 45%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
}

@media (max-width: 480px) {
	h3,
	h4 {
		font-size: 1rem;
		margin-top: 0.5rem;
		text-align: center;
	}

	.loom-record-button {
		font-size: 0.8rem;
		padding: 6px 10px;
	}

	.video-submission-content-container {
		width: 100%;
		padding: 5px;
	}
}
</style>
