<template>
	<div>
		<div v-if="loading" class="background">
			<div v-if="loading" class="logo-position lds-circle">
				<div><img src="@/assets/img/logo.png" /></div>
			</div>
		</div>
		<md-card style="width: 80%; margin: auto">
			<md-card-header>
				<h3 class="title">What Degree Do You Want To Be Funded?</h3>
			</md-card-header>
			<md-card-content>
				<div v-if="Object.keys(selectedDegree).length > 0">
					<div class="w-full flex items-center justify-center">
						<md-card class="education-card" style="width: 70%">
							<md-card-content>
								<div class="education-card-content">
									<div>
										<h3 class="title-degree">{{ selectedDegree.degree }}</h3>
										<p class="sub-text">{{ selectedDegree.university }}</p>
										<p class="sub-text">{{ selectedDegree.yearOfStudy }}</p>
										<p class="sub-text">{{ selectedDegree.faculty }}</p>
										<p class="sub-text">{{ selectedDegree.qualificationType }}</p>
									</div>
									<div class="action-items-container">
										<md-button @click="editDegree" class="md-icon-button edit">
											<md-icon>edit</md-icon>
											<md-tooltip md-direction="bottom">View</md-tooltip>
										</md-button>
										<md-button @click="deleteDegree" class="md-icon-button delete">
											<md-icon>delete</md-icon>
											<md-tooltip md-direction="bottom">Delete</md-tooltip>
										</md-button>
									</div>
								</div>
							</md-card-content>
						</md-card>
					</div>
				</div>
				<div class="w-full flex items-center justify-center">
					<md-button @click="addStudies" class="jb-standard">Add Degree</md-button>
				</div>
			</md-card-content>
		</md-card>
		<modal v-if="showAddStudies">
			<template slot="header">
				<div style="font-size: 2rem">🎓 Add Degree</div>
			</template>
			<template slot="body">
				<div class="md-layout-item">
					<!-- Are you currently studying this degree? -->
					<md-field>
						<md-icon style="margin-right: 10px">school</md-icon>
						<label for="isStudying">Are you currently studying this degree?</label>
						<md-select v-model="isStudying" name="isStudying" id="isStudying" placeholder="Are you currently studying this degree?">
							<md-option value="Yes">Yes</md-option>
							<md-option value="No">No</md-option>
						</md-select>
					</md-field>

					<!-- University (Only show if isStudying has a value) -->
					<md-field v-if="isStudying">
						<md-icon style="margin-right: 10px">school</md-icon>
						<label for="university">University</label>

						<md-select v-model="university" name="university" id="university" placeholder="University">
							<md-option v-for="(university, index) in universityNames" :key="index" :value="university">{{ university }}</md-option>
						</md-select>
					</md-field>

					<!-- Faculty (Only show if university has a value) -->
					<md-field v-if="university">
						<md-icon style="margin-right: 10px">school</md-icon>
						<label for="faculty">Faculty</label>

						<md-select v-model="faculty" name="faculty" id="faculty" placeholder="Faculty">
							<md-option v-for="(faculty, index) in filteredFaculties" :key="index" :value="faculty.name">{{ faculty.name }}</md-option>
						</md-select>
					</md-field>

					<!-- Qualification Type (Only show if faculty has a value) -->
					<md-field v-if="faculty">
						<md-icon style="margin-right: 10px">school</md-icon>
						<label for="qualificationType">Qualification Type</label>

						<md-select v-model="qualificationType" name="qualificationType" id="qualificationType" placeholder="Qualification Type">
							<md-option v-for="(qualification, index) in filteredQualifications" :key="index" :value="qualification.name">
								{{ qualification.name }} ({{ qualification.qualification }})
							</md-option>
						</md-select>
					</md-field>

					<!-- Degree (Only show if qualificationType has a value) -->
					<md-field v-if="qualificationType">
						<md-icon style="margin-right: 10px">school</md-icon>
						<label for="degree">Degree</label>

						<md-select v-model="degree" name="degree" id="degree" placeholder="Degree">
							<md-option v-for="(degree, index) in filteredDegrees" :key="index" :value="degree.name">{{ degree.name }}</md-option>
						</md-select>
					</md-field>

					<!-- Year of Study to be Funded (Only show if degree has a value) -->
					<md-field v-if="degree">
						<md-icon style="margin-right: 10px">school</md-icon>
						<label for="yearOfStudy">Year of Study to be Funded</label>

						<md-select v-model="yearOfStudy" name="yearOfStudy" id="yearOfStudy" placeholder="Year of Study to be Funded">
							<md-option v-for="(year, index) in years" :key="index" :value="year">{{ year }}</md-option>
						</md-select>
					</md-field>
				</div>
			</template>

			<template slot="footer">
				<md-button class="md-button md-success" @click="closeModal"> Okay</md-button>
			</template>
		</modal>
	</div>
</template>

<script>
import { collection, getDocs, doc, setDoc, getDoc, query, where } from 'firebase/firestore';
import { db } from '@/firebase/init';
import Modal from '@/components/Modal.vue';
import firebase from 'firebase/compat/app';
import { BursaryStatuses } from '../../../../../../../constants/bursary-statuses.const';

export default {
	components: {
		Modal,
	},
	data() {
		return {
			studentAlias: null,
			bursaryID: this.$route.params.id,
			universityNames: [],
			faculties: [],
			qualificationTypes: [],
			degrees: [],
			years: [],
			showAddStudies: false,
			isStudying: null,
			university: null,
			faculty: null,
			qualificationType: null,
			degree: null,
			yearOfStudy: null,
			loading: false,
			selectedDegree: {},
			selectedDegreeTest: {
				university: 'University of Fort Hare',
				faculty: 'Health Sciences',
				qualificationType: 'Masters',
				degree: 'MNursSci Psychiatric Nursing',
				yearOfStudy: '1st Year',
			},
		};
	},
	computed: {
		filteredFaculties() {
			return this.faculties.filter(faculty => faculty.university === this.university);
		},
		filteredQualifications() {
			return this.qualificationTypes.filter(qualification => qualification.institution === this.university && qualification.faculty === this.faculty);
		},
		filteredDegrees() {
			return this.degrees.filter(degree => degree.institution === this.university && degree.faculty === this.faculty && degree.qualificationType === this.qualificationType);
		},
	},
	methods: {
		deleteDegree() {
			this.selectedDegree = {};
		},
		editDegree() {
			this.showAddStudies = true;
		},
		addStudies() {
			this.showAddStudies = true;
		},
		closeModal() {
			if (!this.university || !this.faculty || !this.qualificationType || !this.degree || !this.yearOfStudy) {
				alert('All fields must be filled in before proceeding.');
				return;
			}
			this.selectedDegree = {
				university: this.university,
				faculty: this.faculty,
				qualificationType: this.qualificationType,
				degree: this.degree,
				yearOfStudy: this.yearOfStudy,
			};
			this.showAddStudies = false;
		},

		async addDegree() {
			try {
				if (!this.selectedDegree || Object.keys(this.selectedDegree).length === 0) {
					alert('Please select a degree before proceeding.');
					return false;
				}

				const applicationRef = doc(db, 'bursary-applications', `${this.studentAlias}-${this.bursaryID}`);
				this.loading = true;

				await setDoc(applicationRef, {
					bursaryID: this.bursaryID,
					studentAlias: this.studentAlias,
					userId: firebase.auth().currentUser.uid,
					submissionDate: new Date(),
					status: BursaryStatuses.UNSELECTED,
					applicationStatus: BursaryStatuses.IN_PROGRESS,
					selectedDegree: this.selectedDegree,
				});
				this.$emit('selectedDegree', this.selectedDegree);

				this.loading = false;
				return true;
			} catch (error) {
				console.error('Error adding degree:', error);
				this.loading = false;
				return false;
			}
		},
		validate() {
			if (!this.selectedDegree || Object.keys(this.selectedDegree).length === 0) {
				alert('Please select a degree before proceeding.');
				return false;
			} else {
				return true;
			}
		},
	},
	watch: {
		university(newVal) {
			if (newVal) {
				this.faculty = null;
				this.qualificationType = null;
				this.degree = null;
				this.yearOfStudy = null;
			}
		},
		faculty(newVal) {
			if (newVal) {
				this.qualificationType = null;
				this.degree = null;
				this.yearOfStudy = null;
			}
		},
		qualificationType(newVal) {
			if (newVal) {
				this.degree = null;
				this.yearOfStudy = null;
			}
		},
		async fetchUserData() {
			try {
				const currentUser = firebase.auth().currentUser;
				if (currentUser) {
					const usersRef = collection(db, 'users');
					const userQuery = query(usersRef, where('userId', '==', currentUser.uid));
					const userSnapshot = await getDocs(userQuery);
					if (!userSnapshot.empty) {
						const userDoc = userSnapshot.docs[0];
						this.studentAlias = userDoc.data().alias;
					}
				}
			} catch (error) {
				console.error('Error fetching user data:', error);
			}
		},
	},

	async created() {
		try {
			this.loading = true;
			const institutionsDocRef = doc(db, 'Settings', 'Institutions');
			const [namesSnapshot, facultiesSnapshot, qualificationsSnapshot, degreesSnapshot, yearsDoc] = await Promise.all([
				getDocs(collection(institutionsDocRef, 'Names')),
				getDocs(collection(institutionsDocRef, 'Faculties')),
				getDocs(collection(institutionsDocRef, 'Qualification-Types')),
				getDocs(collection(institutionsDocRef, 'Degrees')),
				getDoc(doc(db, 'Settings', 'Drop-down Lists')),
			]);
			this.universityNames = namesSnapshot.docs.map(doc => doc.data().name).sort();
			this.faculties = facultiesSnapshot.docs
				.map(doc => ({
					university: doc.data().institution,
					name: doc.data().name,
				}))
				.sort((a, b) => a.name.localeCompare(b.name));
			const qualificationsSet = new Set();
			qualificationsSnapshot.docs.forEach(doc => {
				const qualification = {
					faculty: doc.data().faculty,
					institution: doc.data().institution,
					name: doc.data().name,
					qualification: doc.data().qualification,
				};
				qualificationsSet.add(JSON.stringify(qualification));
			});
			this.qualificationTypes = Array.from(qualificationsSet)
				.map(item => JSON.parse(item))
				.sort((a, b) => {
					if (a.qualification === 'Undergraduate' && b.qualification !== 'Undergraduate') return -1;
					if (a.qualification !== 'Undergraduate' && b.qualification === 'Undergraduate') return 1;
					return a.name.localeCompare(b.name);
				});
			this.degrees = degreesSnapshot.docs
				.map(doc => ({
					faculty: doc.data().faculty,
					institution: doc.data().institution,
					name: doc.data().name,
					qualification: doc.data().qualification,
					qualificationType: doc.data().qualificationType,
				}))
				.sort((a, b) => a.name.localeCompare(b.name));
			if (yearsDoc.exists()) {
				this.years = yearsDoc.data().Years.sort();
			}
			const currentUser = firebase.auth().currentUser;
			const usersRef = collection(db, 'users');
			const userQuery = query(usersRef, where('userId', '==', currentUser.uid));
			const userSnapshot = await getDocs(userQuery);
			if (!userSnapshot.empty) {
				const userDoc = userSnapshot.docs[0];
				this.studentAlias = userDoc.data().alias;
			}
			this.loading = false;
		} catch (error) {
			this.loading = false;
			console.error('Error fetching data:', error);
		}
	},
};
</script>

<style>
.background {
	width: 100vw;
	height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
}

.sub-text {
	margin-top: -15px;
	font-size: 15px;
}
.title-degree {
	font-weight: bold;
}

.action-items-container {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 20px;
}

.md-icon-button.delete {
	width: 50px !important;
	padding: 8px 0 10px 0 !important;
	background-color: red !important;
}
.md-icon-button.edit {
	width: 50px !important;
	padding: 8px 0 10px 0 !important;
	background-color: #5694f0 !important;
}

.education-card-content {
	width: 100%;
	display: flex;
	justify-content: space-between;
}

@media (max-width: 1600px) {
	.education-card {
		width: 100% !important;
	}
}
@media (max-width: 1280px) {
	.education-card-content {
		flex-direction: column;
	}
}
</style>
