import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
// namespaced compatibility
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';

const firebaseConfig = {
	apiKey: 'AIzaSyCbw3cELtpTrHJE_d3nU7P22_rxVrjgWEc',
	authDomain: 'jobox-staging-fcb0f.firebaseapp.com',
	databaseURL: 'https://jobox-staging-fcb0f-default-rtdb.firebaseio.com',
	projectId: 'jobox-staging-fcb0f',
	storageBucket: 'jobox-staging-fcb0f.appspot.com',
	messagingSenderId: '1083723251750',
	appId: '1:1083723251750:web:cd10b68a62bd8ff9c1a6e7',
	measurementId: 'G-BQFDKYP4QP',
};

// Initialize Firebase
const app = firebase.initializeApp(firebaseConfig); //namespaced compatibility
const firebaseApp = initializeApp(firebaseConfig);

export default app.firestore();
export const db = getFirestore(firebaseApp);
