<template>
	<div class="carousel-container">
		<div class="close-btn">
			<md-button class="md-danger" @click="closeViewer">Close</md-button>
		</div>

		<!-- Display the current PDF document name -->
		<div class="document-name" v-if="documentKeys.length > 0">
			<h2>{{ formatKey(documentKeys[currentIndex]) }}</h2>
		</div>

		<div class="pdf-display" v-if="documentKeys.length > 0">
			<embed :src="documents[documentKeys[currentIndex]].url" class="pdf-embed" />
		</div>

		<div v-if="documents.writtenSubmissionLink">
			<embed :src="documents.writtenSubmissionLink" class="pdf-embed" />
		</div>
		<p v-else>No documents available for this applicant.</p>

		<div class="arrow left" v-if="documentKeys.length > 0" @click="previousPdf">
			<md-icon style="color: white !important">arrow_back</md-icon>
		</div>
		<div class="arrow right" v-if="documentKeys.length > 0" @click="nextPdf">
			<md-icon style="color: white !important">arrow_forward</md-icon>
		</div>

		<div class="indicators" v-if="documentKeys.length > 0">
			<span v-for="(doc, index) in documentKeys" :key="index" :class="{ active: currentIndex === index }" @click="goToPdf(index)"> ● </span>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		documents: { type: Object, required: true },
	},
	data() {
		return {
			currentIndex: 0,
		};
	},
	computed: {
		documentKeys() {
			// Return only keys where document URLs are present
			return Object.keys(this.documents).filter(key => this.documents[key].url);
		},
	},
	methods: {
		formatKey(key) {
			return key.replace(/([a-z])([A-Z])/g, '$1 $2').replace(/^./, str => str.toUpperCase());
		},
		previousPdf() {
			if (this.currentIndex > 0) {
				this.currentIndex--;
			} else {
				this.currentIndex = this.documentKeys.length - 1;
			}
		},
		nextPdf() {
			if (this.currentIndex < this.documentKeys.length - 1) {
				this.currentIndex++;
			} else {
				this.currentIndex = 0;
			}
		},
		goToPdf(index) {
			this.currentIndex = index;
		},
		closeViewer() {
			this.$emit('closeDocumentViewer', false);
		},
	},
};
</script>
<style scoped>
.close-btn {
	z-index: 3000;
	top: 60px;
	right: 100px;
	position: fixed;
	pointer-events: auto;
}

.carousel-container {
	position: fixed;
	width: 100%;
	height: 100vh;
	top: 0;
	left: 0;
	z-index: 2000;
	margin: 0 auto;
	background-color: rgba(0, 0, 0, 0.8);
	display: flex;
	align-items: center;
	justify-content: center;
	overflow: hidden;
}

.document-name {
	position: absolute;
	top: -20px;
	left: 50%;
	transform: translateX(-50%);
	color: white;
	font-size: 1.5rem;
	font-weight: bold;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	width: 90%;
	text-align: center;
}

.pdf-embed {
	width: 50vw;
	margin-top: 2rem;
	height: 90vh;
	border: none;
}

.arrow {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	color: white;
	font-size: 2rem;
	cursor: pointer;
	user-select: none;
	padding: 10px;
	background-color: rgba(0, 0, 0, 0.3);
	border-radius: 50%;
}

.arrow.left {
	left: 100px;
}

.arrow.right {
	right: 100px;
}

.indicators {
	position: absolute;
	bottom: 10px;
	display: flex;
	gap: 5px;
	justify-content: center;
	width: 100%;
}

.indicators span {
	color: white;
	font-size: 1.5rem;
	cursor: pointer;
	user-select: none;
}

.indicators span.active {
	color: orange;
}
</style>
