<template>
	<div v-if="this.bursaries" style="width: 100%; height: 100%; display: flex; align-items: center; justify-content: center; flex-direction: column">
		<div v-if="loading" class="background"></div>
		<div v-if="loading" class="text-center lds-circle">
			<div><img src="@/assets/img/logo.png" /></div>
		</div>
		<BursariesTable :tableData="bursaries" :columns="columns" class="desktop-table" :title="'Open Bursaries'" />
		<BursariesTableMobile :tableData="bursaries" :columns="columns" class="mobile-table" />
	</div>
	<div v-else>
		<div v-if="loading" class="background"></div>
		<div v-if="loading" class="text-center lds-circle">
			<div><img src="@/assets/img/logo.png" /></div>
		</div>
		<h1 class="black centre">There are currently no bursaries posted.</h1>
	</div>
</template>

<script>
import db from '@/firebase/init';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';
import firebase from 'firebase/compat/app';
import BursariesTable from '@/views/dashboard/student/bursaries/BursariesTable.vue';
import BursariesTableMobile from '@/views/dashboard/student/bursaries/BursariesTableMobile.vue';

export default {
	components: { BursariesTable, BursariesTableMobile },
	data() {
		return {
			bursaries: null,
			employer: null,
			loading: true,
			columns: [
				{ label: 'Company', field: 'companyName' },
				{ label: 'Bursary Name', field: 'title' },
				{ label: 'Deadline', field: 'applicationDeadline' },
				{ label: 'Action', field: 'action', route: { name: 'bursary-details', params: { id: 'bursaryId' } } },
			],
		};
	},
	methods: {
		async getBursaryCompany(employerId) {
			try {
				const employerSnapshot = await db.collection('clients').where('userId', '==', employerId).get();
				if (!employerSnapshot.empty) {
					const employer = employerSnapshot.docs[0].data();
					return employer;
				}
			} catch (error) {
				console.error(`Error fetching company name for employerId ${employerId}:`, error);
			}
		},
	},
	async created() {
		try {
			const bursariesSnapshot = await db.collection('bursaries').where('verified', '==', true).where('bursaryClosed', '==', false).get();
			const bursariesPromises = bursariesSnapshot.docs.map(async doc => {
				let bursaryData = doc.data();
				this.employer = await this.getBursaryCompany(bursaryData.employerId);

				return { ...bursaryData, companyName: this.employer.companyName, bursaryId: doc.id };
			});
			this.bursaries = await Promise.all(bursariesPromises);
		} catch (error) {
			console.error('Error fetching bursaries: ', error);
		}
		this.loading = false;
	},
};
</script>

<style scoped>
.container {
	width: 100%;
	display: flex;
	align-items: center;
	flex-direction: column;
}

.container .content_container {
	width: 100%;
	display: flex;
	justify-content: space-between;
}

.container .view_container {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.mobile-table {
	display: none;
}

@media (max-width: 680px) {
	.desktop-table {
		display: none;
	}
	.mobile-table {
		display: block;
	}
}
</style>
