<template>
	<div>
		<h5 class="info-text">Tell us about your most recent studies</h5>
		<div class="md-layout">
			<notifications></notifications>
			<div class="md-layout" v-if="qualifications">
				<div v-for="(qualification, index) in qualifications" :key="index" class="studies-container">
					<div class="study-info-container">
						<p style="font-weight: bold">{{ qualification.institution }}</p>
						<p>{{ qualification.degree }}</p>
						<p>{{ qualification.year }}</p>
						<p>
							{{
								`${new Date(qualification.startDate).getFullYear()} - ${
									qualification.endDate === 'Invalid date' ? 'Present' : new Date(qualification.endDate).getFullYear()
								}`
							}}
						</p>
					</div>

					<div class="action-items-container">
						<md-button class="md-icon-button edit" @click="viewStudies(qualification.qualificationNum)">
							<md-icon>edit</md-icon>
							<md-tooltip md-direction="bottom">View</md-tooltip>
						</md-button>
						<md-button class="md-icon-button delete" @click="deleteStudies(qualification)">
							<md-icon>delete</md-icon>
							<md-tooltip md-direction="bottom">Delete</md-tooltip>
						</md-button>
					</div>
				</div>
			</div>
			<div v-else>
				<h6 class="info-text">You currently have no studies added</h6>
			</div>
		</div>
		<br />
		<br />
		<div style="width: 100%; display: flex; justify-content: center; align-items: center">
			<md-button class="md-plain md-success md-fab-bottom-right" @click="addStudies"> <md-icon>add</md-icon>Add Studies </md-button>
		</div>
		<education-modal :student="student" :selectedQualification="selectedQualification" :status="status" @qualificationAdded="qualificationAdded" />
	</div>
</template>
<script>
import { SlideYDownTransition } from 'vue2-transitions';
import EducationModal from '@/components/Modals/EducationModal';
import db from '@/firebase/init';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import { mapActions } from 'vuex';
import { watchEffect } from 'vue';
import { posthog } from '@/posthog/postHog.js';

export default {
	components: {
		EducationModal,
	},
	data() {
		return {
			user: null,
			student: null,
			qualifications: [],
			qualification: null,
			selectedQualification: null,
			status: null,
		};
	},
	methods: {
		validate() {
			return this.$validator.validateAll().then(res => {
				this.$emit('on-validated', res);
				return res;
			});
		},
		async deleteStudies(selected) {
			await this.student
				.collection('qualifications')
				.doc('qualification' + selected.qualificationNum)
				.delete();

			this.$notify({
				message: 'Your selected studies have been deleted!',
				icon: 'add_alert',
				horizontalAlign: 'center',
				verticalAlign: 'top',
				type: 'success',
			});

			this.qualifications.length = [];
			this.fetchData();
		},
		viewStudies(selected) {
			this.status = 'update';
			this.selectedQualification = selected;
			this.modalShow();
		},
		addStudies() {
			this.status = 'add';
			this.selectedQualification = null;
			this.modalShow();
		},
		qualificationAdded(qualification) {
			posthog.capture('qualifications', {
				university: qualification.institution,
				yearofStudy: qualification.year,
				degree: qualification.degree,
				faculty: qualification.faculty,
				email: qualification.email,
			});
		},
		fetchData() {
			this.user = firebase.auth().currentUser;
			let ref = db.collection('users');
			const unsub = ref
				.where('userId', '==', this.user.uid)
				.get()
				.then(snapshot => {
					snapshot.forEach(doc => {
						this.student = db.collection('students').doc(doc.id);
						this.student.collection('qualifications').onSnapshot(snapshot => {
							const changes = snapshot.docChanges();

							changes.forEach(change => {
								if (change.type === 'added') {
									this.qualifications.push({
										...change.doc.data(),
									});

									this.incrementQualificationNum();

									let qualificationsValid = this.qualifications.length > 0;
									this.$emit('qualificationsValid', qualificationsValid);
								}
								if (change.type === 'modified') {
									let pos = this.qualifications
										.map(function (e) {
											return e.qualificationNum;
										})
										.indexOf(this.selectedQualification);
									this.qualifications = this.qualifications.map((qualificationEl, index) => {
										if (index === pos) {
											return change.doc.data();
										} else {
											return qualificationEl;
										}
									});
								}
								if (change.type === 'removed') {
									let pos = this.qualifications
										.map(function (e) {
											return e.qualificationNum;
										})
										.indexOf(this.selectedQualification);
									this.qualifications.splice(pos, 1);

									let qualificationsValid = this.qualifications.length > 0;
									this.$emit('qualificationsValid', qualificationsValid);
								}
							});
							localStorage.setItem('educationMixpanelList', JSON.stringify(this.qualifications));
						});
					});
				});
		},
		...mapActions(['modalShow', 'incrementQualificationNum']),
	},
	created() {
		this.fetchData();
	},
};
</script>

<style scoped>
.studies-container {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
	padding: 1rem;
	gap: 20px;
	margin-top: 2rem;
	border-radius: 10px;
}

@media (max-width: 650px) {
	.studies-container {
		flex-direction: column;
		justify-content: start;
		align-items: start;
		text-align: left;
	}
	.action-items-container {
		justify-content: space-between;
	}
}
.action-items-container {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 20px;
}

.md-icon-button.delete {
	width: 50px !important;
	padding: 8px 0 10px 0 !important;
	background-color: red !important;
}
.md-icon-button.edit {
	width: 50px !important;
	padding: 8px 0 10px 0 !important;
	background-color: #5694f0 !important;
}
@media only screen and (max-width: 768px) {
	.md-field label {
		font-size: 11px;
	}
}
@media only screen and (max-width: 768px) {
	.md-card {
		overflow: scroll;
	}
}
</style>
