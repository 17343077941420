<template>
	<div class="mobile-filter-container">
		<h1 class="data-table-heading">Bursaries</h1>
		<md-card v-for="(item, index) in tableData" :key="index" class="bursary-card">
			<md-card-header>
				<div class="md-title">
					{{ item[columns.find(column => column.field === 'title').field] }}
				</div>
			</md-card-header>

			<md-card-content>
				<div v-for="(column, colIndex) in columns.filter(col => col.field !== 'title')" :key="colIndex" class="bursary-row">
					<template v-if="column.field !== 'action'">
						<div class="row">
							<span class="bursary-label">{{ column.label }}:</span>
							<span class="bursary-content">{{ item[column.field] }}</span>
						</div>
					</template>
					<template v-else>
						<div class="view-btn">
							<router-link :to="getRoute(column.route, item)">
								<md-button class="jb-standard">View</md-button>
							</router-link>
						</div>
					</template>
				</div>
			</md-card-content>
		</md-card>
	</div>
</template>

<script>
export default {
	props: {
		tableData: {
			type: Array,
			required: true,
		},
		columns: {
			type: Array,
			required: true,
		},
	},
	methods: {
		getRoute(routeConfig, item) {
			if (!routeConfig) return null;
			// Construct route based on dynamic parameters
			const route = { name: routeConfig.name, params: {} };
			for (const [paramKey, itemField] of Object.entries(routeConfig.params)) {
				route.params[paramKey] = item[itemField];
			}
			return route;
		},
	},
};
</script>

<style scoped>
.view-btn {
	width: 100% !important;
	display: flex;
	align-items: center;
	justify-content: flex-end;
}
.mobile-filter-container {
	position: relative;
	padding: 1rem;
	background-color: #f9f9f9;
	border-radius: 8px;
}

.data-table-heading {
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 24px;
	font-weight: bold;
	color: #333;
	margin-bottom: 1rem;
}

.bursary-card {
	margin-bottom: 1rem;
	border-radius: 8px;
	box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
	background: #fff;
	padding: 1rem;
	transition: transform 0.2s, box-shadow 0.2s;
}

.bursary-card:hover {
	transform: translateY(-5px);
	box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

.md-title {
	font-size: 18px;
	font-weight: 600;
	color: black;
	margin-bottom: 0.5rem;
}

.bursary-row {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	padding: 0.5rem 0;
	border-bottom: 1px solid #e0e0e0;
}

.bursary-row:last-child {
	border-bottom: none;
}

.bursary-label {
	font-weight: bold;
	color: #c0c0c0;
	margin-right: 5px;
}

.bursary-content {
	font-size: 16px;
	color: #444;
}

.view-action {
	display: flex;
	justify-content: flex-end;
	margin-top: 1rem;
}
</style>
