<template>
	<div class="md-layout" style="max-width: 2100px; margin: auto; overflow-x: auto">
		<md-button
			style="position: absolute; top: 3px; left: 5px; font-size: 30px !important"
			class="md-simple md-just-icon md-round modal-default-button"
			@click="openShortlistHelp"
		>
			<md-icon>help</md-icon>
		</md-button>

		<div class="md-layout-item md-size-66 md-xsmall-size-80 mx-auto">
			<simple-wizard>
				<wizard-tab>
					<template slot="label">
						<div class="pc-view">Shortlist</div>
						<div class="mobi-view"><i class="fas fa-location-arrow"></i></div>
					</template>
					<Shortlist @bursaryApplicants="bursaryApplicants" />
				</wizard-tab>
			</simple-wizard>
		</div>
	</div>
</template>
<script>
import { WizardTab, Modal } from '@/components';
import SimpleWizard from '@/views/dashboard/client/jobs/applications/wizard/Wizard.vue';
import { helperArticles } from '@/constants/helper-articles.const.js';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';
import Shortlist from './applicants/Shortlist.vue';

export default {
	data() {
		return {
			feedback: null,
			selectedBursaryApplicants: [],
		};
	},
	components: {
		Shortlist,
		SimpleWizard,
		WizardTab,
	},
	methods: {
		openShortlistHelp() {
			window.open(helperArticles.APPLICANT_SHORTLIST_HELP, '_blank');
		},
		back() {
			this.$router.go(-1);
		},
		modalHide() {
			this.modal = false;
		},
		bursaryApplicants(values) {
			this.selectedBursaryApplicants = values;
			console.log(this.selectedBursaryApplicants);
		},
	},
};
</script>
<style scoped>
.md-button .md-button-content i {
	font-size: 30px !important;
}
@media only screen and (max-width: 768px) {
	.pc-view {
		display: none;
	}
	.md-layout-item.md-xsmall-size-80 {
		min-width: 100%;
		overflow-x: hidden;
	}
	.md-card-wizard .nav-pills > li i {
		font-size: 0px;
	}
}

@media only screen and (min-width: 768px) {
	.mobi-view {
		display: none;
	}
}
.md-layout-item.md-size-66 {
	min-width: 100%;
}
</style>
