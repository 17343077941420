<template>
	<div class="carousel-container">
		<div class="close-btn">
			<md-button class="md-danger" @click="closeViewer">Close</md-button>
		</div>

		<div v-if="videoLink" class="section video-submission">
			<h4 class="submission-title"><md-icon class="icon">videocam</md-icon> Video Submission</h4>
			<iframe v-if="videoLink.includes('loom')" :src="`${videoLink}?autoplay=0`" width="640" height="360" frameborder="0" style="margin: auto" allowfullscreen></iframe>
			<a v-else :href="videoLink" target="_blank" class="document-link">View Video</a>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		videoLink: { type: String },
	},
	data() {
		return {
			currentIndex: 0,
		};
	},
	methods: {
		closeViewer() {
			this.$emit('closeViedoViewer', false);
		},
	},
};
</script>
<style scoped>
.close-btn {
	z-index: 3000;
	top: 60px;
	right: 100px;
	position: fixed;
	pointer-events: auto;
}

.carousel-container {
	position: fixed;
	width: 100%;
	height: 100vh;
	top: 0;
	left: 0;
	z-index: 2000;
	margin: 0 auto;
	background-color: rgba(0, 0, 0, 0.8);
	display: flex;
	align-items: center;
	justify-content: center;
	overflow: hidden;
}
</style>
