const getDefaultState = () => {
	return {
		btnText: 'Next',
		submittedDocuments: {},
		videoSubmissionLink: '',
		writtenSubmissionLink: '',
		selectedDegree: {},
	};
};

const state = getDefaultState();

export default {
	state,
	getters: {
		getBtnText: state => state.btnText,
		getSubmittedDocuments: state => state.submittedDocuments,
		getVideoSubmissionLink: state => state.videoSubmissionLink,
		getWrittenSubmissionLink: state => state.writtenSubmissionLink,
		getSelectedDegree: state => state.selectedDegree,
	},
	mutations: {
		setBtnText: (state, newText) => {
			state.btnText = newText;
		},
		setSubmittedDocuments: (state, documents) => {
			state.submittedDocuments = { ...state.submittedDocuments, ...documents };
		},
		setVideoSubmissionLink: (state, link) => {
			state.videoSubmissionLink = link;
		},
		setWrittenSubmissionLink: (state, link) => {
			state.writtenSubmissionLink = link;
		},
		setSelectedDegree: (state, degree) => {
			state.selectedDegree = degree;
		},
		resetState(state) {
			Object.assign(state, getDefaultState());
		},
	},
	actions: {
		updateBtnText: ({ commit }, newText) => {
			commit('setBtnText', newText);
		},
		saveSubmittedDocuments: ({ commit }, documents) => {
			commit('setSubmittedDocuments', documents);
		},
		saveVideoSubmissionLink: ({ commit }, link) => {
			commit('setVideoSubmissionLink', link);
		},
		saveWrittenSubmissionLink: ({ commit }, link) => {
			commit('setWrittenSubmissionLink', link);
		},
		saveSelectedDegree: ({ commit }, degree) => {
			commit('setSelectedDegree', degree);
		},
		resetState({ commit }) {
			commit('resetState');
		},
	},
};
