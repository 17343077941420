import Vue from 'vue';
import Vuex from 'vuex';
import InboundPayment from './modules/payment/inbound/store';
import OutboundPayment from './modules/payment/outbound/store';
import Login from './modules/login/store';
import Forgot from './modules/forgot/store';
import Feedback from './modules/feedback/store';
import Register from './modules/register/store';
import StudentRegister from './modules/register/student/store';
import ClientRegister from './modules/register/client/store';
import Support from './modules/support/store';
import Micro from './modules/dashboard/client/jobs/micro/store';
import editJob from './modules/dashboard/client/jobs/editJob/store';
import Institution from './modules/dashboard/institution/store';
import bursaryStore from './modules/dashboard/student/bursaries/bursary';
Vue.use(Vuex);

export const store = new Vuex.Store({
	modules: {
		InboundPayment,
		OutboundPayment,
		Login,
		Forgot,
		Register,
		StudentRegister,
		ClientRegister,
		Feedback,
		Support,
		Micro,
		editJob,
		Institution,
		bursaryStore,
	},
});
