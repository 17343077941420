<template>
	<div class="md-layout">
		<div class="md-layout-item">
			<md-card>
				<md-button
					style="position: absolute; top: 3px; left: 10px; font-size: 30px !important"
					class="md-simple md-just-icon md-round modal-default-button"
					@click="openJobsHelp"
				>
					<md-icon>help</md-icon>
				</md-button>
				<h1 style="display: flex; align-items: center; justify-content: center">Open Jobs</h1>
				<md-card-header class="md-card-header-icon md-card-header-green">
					<div class="card-icon">
						<md-icon>assignment</md-icon>
					</div>
					<div style="width: 100%; display: flex; align-items: center; justify-content: flex-end">
						<md-button style="background-color: #448aff !important" @click="handleClick">Filter</md-button>
					</div>
				</md-card-header>
				<job-filter-modal
					:filterClicked="filterClicked"
					:data="data"
					@closeFilterModal="closeFilterModal"
					@selectedCategory="handleCategoryChange"
					@selectedEmploymentType="handleEmploymentTypeChange"
					@selectedLocations="handleLocationChange"
				>
				</job-filter-modal>
				<md-card-content>
					<md-table
						:value="queriedData"
						:md-sort.sync="currentSort"
						:md-sort-order.sync="currentSortOrder"
						:md-sort-fn="customSort"
						class="paginated-table table-striped table-hover"
					>
						<md-table-toolbar>
							<md-field>
								<label for="pages">Per page</label>
								<md-select v-model="pagination.perPage" name="pages">
									<md-option v-for="item in pagination.perPageOptions" :key="item" :label="item" :value="item">
										{{ item }}
									</md-option>
								</md-select>
							</md-field>

							<md-field>
								<md-input type="search" class="mb-3" clearable style="width: 200px" placeholder="Search records" v-model="searchQuery"> </md-input>
							</md-field>
						</md-table-toolbar>

						<md-table-row slot="md-table-row" slot-scope="{ item }">
							<md-table-cell md-label="Company Name" md-sort-by="company-name">{{ item.companyName }}</md-table-cell>
							<md-table-cell md-label="Name" md-sort-by="name">{{ item.name }}</md-table-cell>
							<md-table-cell md-label="Salary" md-sort-by="salary">{{ formatBudget(item) }}</md-table-cell>
							<md-table-cell md-label="Location">{{ item.location }}</md-table-cell>
							<md-table-cell md-label="Type">{{ item.type }}</md-table-cell>
							<md-table-cell md-label="Category">{{ item.category }}</md-table-cell>
							<md-table-cell md-label="Action"
								><router-link :to="{ name: 'micro-application', params: { id: item.id } }">
									<md-button class="jb-standard">View</md-button>
								</router-link></md-table-cell
							>
						</md-table-row>
					</md-table>
				</md-card-content>
				<md-card-actions md-alignment="space-between">
					<div class="">
						<p class="card-category">Showing {{ from + 1 }} to {{ to }} of {{ total }} entries</p>
					</div>
					<pagination class="pagination-no-border pagination-success" v-model="pagination.currentPage" :per-page="pagination.perPage" :total="total"> </pagination>
				</md-card-actions>
			</md-card>
		</div>
	</div>
</template>

<script>
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';
import { Pagination } from '@/components';
import JobFilterModal from '@/components/Modals/JobFilterModal.vue';
import { helperArticles } from '@/constants/helper-articles.const';
export default {
	components: {
		Pagination,
		JobFilterModal,
	},
	props: {
		data: {},
		columns: { type: Array },
	},
	data() {
		return {
			filterClicked: false,
			currentSort: 'name',
			currentSortOrder: 'asc',
			pagination: {
				perPage: 10,
				currentPage: 1,
				perPageOptions: [5, 10, 15, 20, 25, 50],
				total: 0,
			},
			footerTable: this.columns,
			searchQuery: '',
			searchedData: [],
			jobCategories: [],
			employmentTypes: [],
			locations: [],
			newData: null,
		};
	},
	computed: {
		queriedData() {
			let result = this.data;

			if (this.searchQuery.length > 0) {
				result = result.filter(job => {
					const name = job.name.toLowerCase();
					const keyword = this.searchQuery.toLowerCase();
					const companyName = job.companyName.toLowerCase();
					return name.includes(keyword) || companyName.includes(keyword);
				});
			}

			if (this.jobCategories.length > 0 || this.employmentTypes.length > 0 || this.locations.length > 0) {
				result = result.filter(job => {
					const categoryMatch = this.jobCategories.length === 0 || this.jobCategories.includes(job.category);
					const typeMatch = this.employmentTypes.length === 0 || this.employmentTypes.includes(job.type);
					const locationMatch = this.locations.length === 0 || this.locations.includes(job.location);
					return categoryMatch && typeMatch && locationMatch;
				});
			}
			this.takeResults(result);
			return result.slice(this.from, this.to);
		},
		to() {
			let highBound = this.from + this.pagination.perPage;
			if (this.total < highBound) {
				highBound = this.total;
			}
			return highBound;
		},
		from() {
			return this.pagination.perPage * (this.pagination.currentPage - 1);
		},
		total() {
			return this.searchedData?.length > 0 ? this.searchedData?.length : this.newData?.length;
		},
	},
	methods: {
		openJobsHelp() {
			window.open(helperArticles.OPEN_JOBS_HELP, '_blank');
		},
		takeResults(result) {
			this.newData = result;
		},
		handleCategoryChange(data) {
			this.jobCategories = data;
		},
		handleEmploymentTypeChange(data) {
			this.employmentTypes = data;
		},
		handleLocationChange(data) {
			this.locations = data;
		},
		closeFilterModal(data) {
			this.filterClicked = data;
		},
		handleClick() {
			this.filterClicked = true;
		},
		formatBudget(job) {
			let budget = '';
			if (job.budget.min || job.budget.max) {
				let { min, max } = job.budget;
				budget = 'R' + min + ' ' + '-' + ' ' + 'R' + max;
				return budget;
			} else {
				budget = 'R' + job.budget;
				return budget;
			}
		},
		customSort(value) {
			return value.sort((a, b) => {
				const sortBy = this.currentSort;
				if (this.currentSortOrder === 'desc') {
					return a[sortBy].localeCompare(b[sortBy]);
				}
				return b[sortBy].localeCompare(a[sortBy]);
			});
		},
	},
};
</script>

<style scoped>
.md-table + .md-table {
	margin-top: 16px;
}

.md-table-cell-container {
	display: flex;
	justify-content: flex-start;
}

.md-table-cell-container .md-button {
	width: auto !important;
}

.md-card [class*='md-card-header-'] {
	position: relative !important;
}
.md-button .md-button-content i {
	font-size: 30px !important;
}
</style>
