<template>
	<div class="bursary-filter-overlay" v-if="visible">
		<div class="bursary-filter">
			<div class="close-btn-container">
				<md-button class="md-danger" @click="$emit('close')">Close</md-button>
			</div>
			<h3>Filter Bursary Applicants</h3>

			<md-field class="filter-field" v-for="(options, key) in filterOptions" :key="key">
				<md-select v-model="filters[key]" :placeholder="key.charAt(0).toUpperCase() + key.slice(1)">
					<md-option v-for="option in options" :key="option" :value="option">{{ option }}</md-option>
				</md-select>
			</md-field>

			<md-switch v-model="filters.favourite" :true-value="true" :false-value="false">Show Only Favourited</md-switch>

			<div class="btn-container">
				<md-button class="jobox-std" @click="$emit('apply')" style="background-color: #5081cb !important">Apply</md-button>
				<md-button class="md-success" @click="$emit('reset')">Reset Filters</md-button>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		visible: {
			type: Boolean,
			required: true,
		},
		filters: {
			type: Object,
			required: true,
		},
		uniqueValues: {
			type: Object,
			required: true,
		},
	},
	computed: {
		filterOptions() {
			return {
				gender: this.uniqueValues.gender || [],
				university: this.uniqueValues.university || [],
				degree: this.uniqueValues.degree || [],
				yearOfStudy: this.uniqueValues.yearOfStudy || [],
				qualificationType: this.uniqueValues.qualificationType || [],
				ethnicity: this.uniqueValues.ethnicity || [],
				status: this.uniqueValues.status || [],
			};
		},
	},
};
</script>

<style scoped>
.bursary-filter-overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100vh;
	background-color: rgba(0, 0, 0, 0.5);
	z-index: 1000;
	display: flex;
	align-items: center;
	justify-content: center;
}

.bursary-filter {
	width: 800px;
	height: fit-content;
	background-color: white;
	padding: 40px;
	border-radius: 10px;
}

.filter-field {
	margin: 2rem 0;
}

.close-btn-container {
	display: flex;
	justify-content: flex-end;
}

.btn-container {
	display: flex;
	justify-content: space-between;
	margin-top: 2rem;
}
</style>
