<template>
	<div>
		<div class="review-container" v-if="submittedDocuments">
			<div v-if="Object.keys(selectedDegree).length > 0">
				<div class="w-full">
					<div class="w-full">
						<md-card>
							<h3 class="text-center">
								<md-icon style="font-size: 2rem !important; margin-bottom: 5px !important; margin-right: 10px"> school </md-icon> Degree To Be Funded
							</h3>
							<md-card-content>
								<div>
									<h3 class="title-degree">{{ selectedDegree.degree }}</h3>
									<p class="sub-text">{{ selectedDegree.university }}</p>
									<p class="sub-text">{{ selectedDegree.yearOfStudy }}</p>
									<p class="sub-text">{{ selectedDegree.faculty }}</p>
									<p class="sub-text">{{ selectedDegree.qualificationType }}</p>
								</div>
							</md-card-content>
						</md-card>
					</div>
				</div>
			</div>
			<div class="document-list">
				<md-card>
					<h3 class="text-center">
						<md-icon style="font-size: 2rem !important; margin-bottom: 5px !important; margin-right: 10px"> assignment </md-icon> Submitted Documents
					</h3>
					<md-card-content>
						<div v-for="(document, docType) in submittedDocuments" :key="docType" class="document-item">
							<div style="display: flex; width: 80%">
								<div style="width: 30px">
									<md-icon class="icon">insert_drive_file</md-icon>
								</div>
								<span class="document-name">{{ formatDocumentName(docType) }}</span>
							</div>

							<div style="width: 20%; display: flex; justify-content: flex-end">
								<a :href="document.url" target="_blank" class="document-link">View Document</a>
							</div>
						</div>
					</md-card-content>
				</md-card>
			</div>
			<div v-if="videoSubmissionLink" class="section video-submission">
				<md-card>
					<md-card-content>
						<h3 class="text-center">
							<md-icon style="font-size: 2rem !important; margin-bottom: 5px !important; margin-right: 10px"> videocam </md-icon> Video Submission
						</h3>
						<iframe
							v-if="videoSubmissionLink.includes('loom')"
							:src="`${videoSubmissionLink}?autoplay=0`"
							width="640"
							height="360"
							frameborder="0"
							style="margin: auto"
							allowfullscreen
						></iframe>
						<a v-else :href="videoSubmissionLink" target="_blank" class="document-link">View Video</a>
					</md-card-content>
				</md-card>
			</div>

			<div v-if="writtenSubmissionLink" class="section written-submission">
				<md-card>
					<md-card-content>
						<h3 class="text-center">
							<md-icon style="font-size: 2rem !important; margin-bottom: 5px !important; margin-right: 10px"> description </md-icon> Written Submission
						</h3>
						<a class="document-link" :href="writtenSubmissionLink" target="_blank">View Written Submission</a>
					</md-card-content>
				</md-card>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
	computed: {
		...mapGetters({
			submittedDocuments: 'getSubmittedDocuments',
			videoSubmissionLink: 'getVideoSubmissionLink',
			writtenSubmissionLink: 'getWrittenSubmissionLink',
			selectedDegree: 'getSelectedDegree',
		}),
	},
	methods: {
		formatDocumentName(docType) {
			return docType.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase());
		},
	},
};
</script>

<style scoped>
.review-container {
	background-color: #ffffff;
	border: 1px solid #e0e0e0;
	border-radius: 10px;
	padding: 20px;
	max-width: 800px;
	margin: 40px auto;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.document-item {
	display: flex;
	margin: 2rem 0 2rem 0;
}
</style>
