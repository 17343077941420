<template>
	<div>
		<md-table md-card>
			<md-table-toolbar>
				<div class="w-full flex items-center justify-between">
					<h1 class="md-title">Bursary Applicants</h1>
					<md-button class="filter-btn" @click="openFilterMenu">
						<md-icon>filter_alt</md-icon>
					</md-button>
				</div>
				<md-field class="filter-field">
					<label>Search Applicants</label>
					<md-input v-model="searchQuery"></md-input>
				</md-field>
			</md-table-toolbar>

			<md-table-row>
				<md-table-head>Name</md-table-head>
				<md-table-head>Surname</md-table-head>
				<md-table-head>Year of Study</md-table-head>
				<md-table-head>Degree</md-table-head>
				<md-table-head>Status</md-table-head>
				<md-table-head></md-table-head>
				<md-table-head></md-table-head>
				<md-table-head></md-table-head>
			</md-table-row>

			<md-table-row v-for="(applicant, index) in filteredApplicants" :key="index">
				<md-table-cell>{{ applicant.name }}</md-table-cell>
				<md-table-cell>{{ applicant.surname }}</md-table-cell>
				<md-table-cell>{{ applicant.mostRecentQualification.year }}</md-table-cell>
				<md-table-cell>{{ applicant.mostRecentQualification.degree }}</md-table-cell>
				<md-table-cell>{{ applicant.status }}</md-table-cell>

				<md-table-cell style="text-align: center">
					<md-button class="md-success" @click="openUserProfile(applicant)">View Profile</md-button>
				</md-table-cell>
				<md-table-cell style="text-align: left">
					<md-button class="select-bursary-btn filter-btn" @click="selectForBursary(applicant)">
						{{ applicant.status === 'Selected' ? 'Unselect' : 'Select for Bursary' }}
					</md-button>
				</md-table-cell>

				<md-table-cell>
					<div @click="toggleFavourite(applicant)" class="favourite-candidate">
						<md-icon :style="{ color: applicant.favourite ? 'orange' : 'lightgrey' }">star</md-icon>
					</div>
				</md-table-cell>

				<md-table-cell style="text-align: right !important">
					<md-menu>
						<md-button class="md-icon-button" md-menu-trigger>
							<md-icon>more_vert</md-icon>
						</md-button>
						<md-menu-content>
							<md-menu-item @click="viewDocuments(applicant)">View Required Documents</md-menu-item>
							<md-menu-item v-if="applicant.additionalSubmissions" @click="viewVideoSubmissions(applicant)">View Video Submissions</md-menu-item>
							<md-menu-item v-if="applicant.additionalSubmissions" @click="viewWrittenSubmission(applicant)">View Written Submissions</md-menu-item>
						</md-menu-content>
					</md-menu>
				</md-table-cell>
			</md-table-row>
		</md-table>

		<BursaryApplicantFilter :visible="filterMenuVisible" :filters="filters" :uniqueValues="uniqueValues" @close="closeFilterMenu" @apply="applyFilters" @reset="resetFilters" />

		<modal v-if="profileModalVisible" @close="profileModalHide">
			<template slot="header">
				<md-button class="md-simple md-just-icon md-round modal-default-button" @click="profileModalHide">
					<md-icon style="font-size: 2rem !important; color: black !important">clear</md-icon>
				</md-button>
			</template>

			<template slot="body">
				<CandidateProfile :userProfile="selectedApplicant" :showFavoriteButton="true" :jobId="this.$route.params.id" />
			</template>

			<template slot="footer">
				<!-- action button here -->
			</template>
		</modal>
		<div v-if="isViewDocumentsOpened">
			<PdfViewer :documents="selectedApplicant.submittedDocuments" @closeDocumentViewer="closeDocumentViewer" />
		</div>

		<div v-if="isViewVideoOpened">
			<VideoViewer @closeViedoViewer="closeViedoViewer" :videoLink="videoLink" />
		</div>

		<div v-if="isViewWrittenSubmissionOpened">
			<PdfViewer :documents="writtenSubmissionLink" @closeDocumentViewer="closeDocumentViewer" />
		</div>
	</div>
</template>

<script>
import firebase from 'firebase/compat/app';
import BursaryApplicantFilter from '@/components/Filters/BursaryApplicantFilter.vue';

import db from '@/firebase/init';
import 'firebase/compat/firestore';
import CandidateProfile from '@/components/Profile/CandidateProfile.vue';
import { Modal } from '@/components';
import PdfViewer from '@/components/PdfViewer.vue';
import VideoViewer from '@/components/VideoViewer.vue';
import { BursaryStatuses } from '../../../../../../constants/bursary-statuses.const';
import { doc, updateDoc, collection, getDocs, query, where, orderBy, limit } from 'firebase/firestore';
export default {
	components: { CandidateProfile, Modal, PdfViewer, VideoViewer, BursaryApplicantFilter },
	data() {
		return {
			isViewDocumentsOpened: false,
			isViewWrittenSubmissionOpened: false,
			isViewVideoOpened: false,
			videoLink: null,
			writtenSubmissionLink: null,
			selectedApplicant: null,
			profileModalVisible: false,
			searchQuery: '',
			filterMenuVisible: false,
			selectedBursaryApplicants: [],
			filters: {
				gender: null,
				degree: null,
				yearOfStudy: null,
				qualificationType: null,
				ethnicity: null,
				university: null,
				status: null,
				favourite: false,
			},
			applicants: [],
			uniqueValues: {
				gender: [],
				degree: [],
				yearOfStudy: [],
				qualificationType: [],
				ethnicity: [],
				university: [],
				status: [],
				favourite: [],
			},
		};
	},
	created() {
		this.fetchBursaryApplicants();
	},
	methods: {
		viewWrittenSubmission(writtenSubmissionLink) {
			this.writtenSubmissionLink = writtenSubmissionLink.additionalSubmissions;
			this.isViewWrittenSubmissionOpened = true;
		},
		viewVideoSubmissions(videoLink) {
			this.videoLink = videoLink.additionalSubmissions.videoSubmissionLink;
			if (this.videoLink.includes('loom')) {
				this.isViewVideoOpened = true;
			} else {
				window.open(this.videoLink, '_blank');
			}
		},
		closeViedoViewer(value) {
			this.isViewVideoOpened = value;
		},
		toggleFavourite(applicant) {
			const bursaryId = this.$route.params.bursaryId;
			const documentId = `${applicant.alias}-${bursaryId}`;

			const newFavourite = !applicant.favourite;

			db.collection('bursary-applications')
				.doc(documentId)
				.update({ favourite: newFavourite })
				.then(() => {
					applicant.favourite = newFavourite;
					console.log(`Favourite status updated to ${newFavourite}`);
				})
				.catch(error => {
					console.error('Error updating favourite status:', error);
				});
		},

		viewDocuments(applicant) {
			if (applicant.submittedDocuments && Object.keys(applicant.submittedDocuments).length > 0) {
				this.selectedApplicant = applicant;
				this.isViewDocumentsOpened = true;
			}
		},
		closeDocumentViewer(value) {
			this.isViewDocumentsOpened = value;
			this.isViewWrittenSubmissionOpened = value;
		},
		profileModalHide() {
			this.profileModalVisible = false;
		},
		openUserProfile(applicant) {
			console.log(applicant);
			this.selectedApplicant = applicant;
			this.profileModalVisible = true;
		},
		async fetchBursaryApplicants() {
			const bursaryId = this.$route.params.bursaryId;

			try {
				const bursaryApplicationsQuery = query(collection(db, 'bursary-applications'), where('bursaryID', '==', bursaryId));

				const bursaryApplicationsSnapshot = await getDocs(bursaryApplicationsQuery);
				const studentIds = bursaryApplicationsSnapshot.docs.map(doc => doc.data().userId);

				const studentPromises = studentIds.map(async studentId => {
					const studentQuery = query(collection(db, 'students'), where('userId', '==', studentId));
					const studentSnapshot = await getDocs(studentQuery);
					const studentDoc = studentSnapshot.docs[0];
					const studentData = studentDoc.data();

					const qualificationQuery = query(collection(db, `students/${studentDoc.id}/qualifications`), orderBy('lastModified', 'desc'), limit(1));

					const userQuery = query(collection(db, 'users'), where('userId', '==', studentData.userId));

					const [qualificationsSnapshot, userSnapshot] = await Promise.all([getDocs(qualificationQuery), getDocs(userQuery)]);

					let mostRecentQualification = {};
					if (!qualificationsSnapshot.empty) {
						mostRecentQualification = qualificationsSnapshot.docs[0].data();
					}

					let userData = {};
					if (!userSnapshot.empty) {
						userData = userSnapshot.docs[0].data();
					}

					const applicationQuery = query(collection(db, 'bursary-applications'), where('userId', '==', studentId), where('bursaryID', '==', bursaryId));

					const applicationSnapshot = await getDocs(applicationQuery);
					const applicationData = applicationSnapshot.docs[0]?.data() || {};
					const submittedDocuments = applicationData.submittedDocuments || {};

					return {
						alias: studentDoc.id,
						name: userData.name || '',
						surname: userData.surname || '',
						yearOfStudy: studentData.yearOfStudy,
						degree: studentData.degree,
						mostRecentQualification,
						status: applicationData.status,
						gender: studentData.gender,
						favourite: applicationData.favourite || false,
						additionalSubmissions: applicationData.additionalSubmissions || null,
						race: studentData.race,
						submittedDocuments,
					};
				});

				const applicants = (await Promise.all(studentPromises)).filter(applicant => applicant !== null);
				this.applicants = applicants;

				this.populateUniqueFilterValues();
			} catch (error) {
				console.error('Error fetching bursary applicants:', error);
			}
		},
		populateUniqueFilterValues() {
			this.uniqueValues.gender = [...new Set(this.applicants.map(applicant => applicant.gender))];
			this.uniqueValues.favourite = [...new Set(this.applicants.map(applicant => applicant.favourite.toString()))];
			this.uniqueValues.qualificationType = [...new Set(this.applicants.map(applicant => applicant.mostRecentQualification.graduateStatus))];
			this.uniqueValues.ethnicity = [...new Set(this.applicants.map(applicant => applicant.race))];
			this.uniqueValues.race = [...new Set(this.applicants.map(applicant => applicant.race))];
			this.uniqueValues.degree = [...new Set(this.applicants.map(applicant => applicant.mostRecentQualification.degree))];
			this.uniqueValues.yearOfStudy = [...new Set(this.applicants.map(applicant => applicant.mostRecentQualification.year))];
			this.uniqueValues.university = [...new Set(this.applicants.map(applicant => applicant.mostRecentQualification.institution))];
			this.uniqueValues.status = [BursaryStatuses.SELECTED, BursaryStatuses.UNSELECTED];
		},
		viewProfile(applicantId) {
			this.$router.push(`/applicant/${applicantId}`);
		},
		selectForBursary(applicant) {
			const bursaryId = this.$route.params.bursaryId;
			const documentId = `${applicant.alias}-${bursaryId}`;

			const newStatus = applicant.status === BursaryStatuses.SELECTED ? BursaryStatuses.UNSELECTED : BursaryStatuses.SELECTED;

			const docRef = doc(db, 'bursary-applications', documentId);

			updateDoc(docRef, { status: newStatus }).then(() => {
				applicant.status = newStatus;
			});
		},
		openFilterMenu() {
			this.filterMenuVisible = true;
		},
		closeFilterMenu() {
			this.filterMenuVisible = false;
		},
		applyFilters() {
			this.filterMenuVisible = false;
		},
		resetFilters() {
			this.filters = {
				gender: null,
				degree: null,
				yearOfStudy: null,
				qualificationType: null,
				ethnicity: null,
				university: null,
				status: null,
				favourite: false,
			};
		},
	},
	computed: {
		filteredApplicants() {
			let filtered = this.applicants;

			if (this.searchQuery) {
				filtered = filtered.filter(applicant => `${applicant.name} ${applicant.surname}`.toLowerCase().includes(this.searchQuery.toLowerCase()));
			}

			if (this.filters.gender) {
				filtered = filtered.filter(applicant => applicant.gender === this.filters.gender);
			}

			if (this.filters.favourite) {
				filtered = filtered.filter(applicant => applicant.favourite);
			}

			if (this.filters.race) {
				filtered = filtered.filter(applicant => applicant.race === this.filters.race);
			}

			if (this.filters.degree) {
				filtered = filtered.filter(applicant => applicant.mostRecentQualification.degree === this.filters.degree);
			}

			if (this.filters.yearOfStudy) {
				filtered = filtered.filter(applicant => applicant.mostRecentQualification.year === this.filters.yearOfStudy);
			}

			if (this.filters.qualificationType) {
				filtered = filtered.filter(applicant => applicant.mostRecentQualification.graduateStatus === this.filters.qualificationType);
			}

			if (this.filters.ethnicity) {
				filtered = filtered.filter(applicant => applicant.race === this.filters.ethnicity);
			}

			if (this.filters.university) {
				filtered = filtered.filter(applicant => applicant.mostRecentQualification.institution === this.filters.university);
			}

			if (this.filters.status) {
				filtered = filtered.filter(applicant => applicant.status === this.filters.status);
			}

			return filtered;
		},
	},
};
</script>

<style scoped>
.favourite-candidate:hover {
	cursor: pointer;
	width: fit-content;
}
.select-bbursary-btn {
	background-color: #5c83e3 !important;
}
.md-card {
	overflow: auto !important;
}
.md-title {
	font-size: 1.5rem !important;
}
.actions {
	width: 100%;
	display: flex;
	align-items: center;
	gap: 10px;
}

.action-btn {
	font-size: 14px;
	padding: 5px 10px;
}

.md-button.md-icon-button {
	font-size: 20px;
	margin-left: 10px;
}

.md-table-cell-container .md-button {
	width: auto;
	padding: 0;
	margin: 0 10px 0 10px;
}

.filter-btn {
	background-color: #558fe6 !important;
}

.filter-btn:hover {
	background-color: #4384e6 !important;
}
</style>
