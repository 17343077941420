<template>
	<div>
		<BursaryApplicationsTable
			:data="bursaries"
			:columns="[
				{ label: 'Bursary Name', key: 'title' },
				{ label: 'Application Deadline', key: 'applicationDeadline' },
			]"
		/>
	</div>
</template>

<script>
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import BursaryApplicationsTable from '@/components/Tables/BursaryApplicationsTable.vue';
export default {
	components: {
		BursaryApplicationsTable,
	},
	data() {
		return {
			clientId: firebase.auth().currentUser.uid,
			bursaries: [],
		};
	},
	async created() {
		let bursaryQuery = await firebase.firestore().collection('bursaries').where('employerId', '==', this.clientId).get();
		this.bursaries = bursaryQuery.docs.map(doc => ({
			id: doc.id,
			...doc.data(),
		}));
		const bursaryApplicationPromises = this.bursaries.map(async bursary => {
			let bursaryApplicationsQuery = await firebase.firestore().collection('bursary-applications').where('bursaryID', '==', bursary.id).get();
			return {
				...bursary,
				applications: bursaryApplicationsQuery.docs.map(appDoc => ({
					id: appDoc.id,
					...appDoc.data(),
				})),
			};
		});
		this.bursaries = await Promise.all(bursaryApplicationPromises);
	},
};
</script>

<style></style>
