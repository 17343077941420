<template>
	<div v-if="bursaries.length > 0" style="width: 100%; height: 100%; display: flex; align-items: center; justify-content: center; flex-direction: column">
		<div v-if="loading" class="background"></div>
		<div v-if="loading" class="text-center lds-circle">
			<div><img src="@/assets/img/logo.png" /></div>
		</div>
		<BursariesTable :tableData="bursaries" :columns="columns" class="desktop-table" :title="'Bursary Applications'" />
		<BursariesTableMobile :tableData="bursaries" :columns="columns" class="mobile-table" />
	</div>
	<div v-else>
		<div v-if="loading" class="background"></div>
		<div v-if="loading" class="text-center lds-circle">
			<div><img src="@/assets/img/logo.png" /></div>
		</div>
		<h1 class="black centre">There are currently no bursaries posted.</h1>
	</div>
</template>

<script>
import { getFirestore, collection, query, where, getDocs } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import BursariesTable from '../BursariesTable.vue';
import BursariesTableMobile from '../BursariesTableMobile.vue';

export default {
	components: { BursariesTable, BursariesTableMobile },
	data() {
		return {
			bursaries: [],
			loading: true,
			columns: [
				{ label: 'Company Name', field: 'companyName' },
				{ label: 'Bursary Name', field: 'title' },
				{ label: 'Deadline', field: 'applicationDeadline' },
				{ label: 'Status', field: 'applicationStatus' },
				{
					label: 'Action',
					field: 'action',
					route: {
						name: 'bursary-application',
						params: { id: 'bursaryId' },
					},
				},
			],
		};
	},
	methods: {
		async getBursaryCompany(employerId) {
			try {
				const db = getFirestore();
				const employerQuery = query(collection(db, 'clients'), where('userId', '==', employerId));
				const employerSnapshot = await getDocs(employerQuery);
				if (!employerSnapshot.empty) {
					const employer = employerSnapshot.docs[0].data();
					return employer;
				}
			} catch (error) {
				console.error(`Error fetching company name for employerId ${employerId}:`, error);
			}
		},
		async fetchUserApplications() {
			try {
				const db = getFirestore();
				const auth = getAuth();
				const user = auth.currentUser;
				if (user) {
					const applicationsQuery = query(collection(db, 'bursary-applications'), where('userId', '==', user.uid));
					const applicationsSnapshot = await getDocs(applicationsQuery);

					const applications = await Promise.all(
						applicationsSnapshot.docs.map(async applicationDoc => {
							const applicationData = applicationDoc.data();
							const bursaryData = await this.fetchBursary(applicationData.bursaryID);

							// Merge application and bursary data
							return {
								...applicationData,
								...bursaryData,
								bursaryId: applicationData.bursaryID,
							};
						})
					);
					this.bursaries = applications;
				}
			} catch (error) {
				console.error('Error fetching applications for the user: ', error);
			}
		},
		async fetchBursary(bursaryId) {
			try {
				const db = getFirestore();
				const bursaryQuery = query(collection(db, 'bursaries'), where('__name__', '==', bursaryId));
				const bursarySnapshot = await getDocs(bursaryQuery);
				if (!bursarySnapshot.empty) {
					const bursaryData = bursarySnapshot.docs[0].data();
					const employer = await this.getBursaryCompany(bursaryData.employerId);
					return {
						...bursaryData,
						companyName: employer ? employer.companyName : 'Unknown Company',
					};
				}
				return {};
			} catch (error) {
				console.error(`Error fetching bursary with id ${bursaryId}:`, error);
				return {};
			}
		},
	},
	async created() {
		try {
			await this.fetchUserApplications();
		} catch (error) {
			console.error('Error during initial data fetch: ', error);
		}
		this.loading = false;
	},
};
</script>

<style scoped>
.container {
	width: 100%;
	display: flex;
	align-items: center;
	flex-direction: column;
}

.container .content_container {
	width: 100%;
	display: flex;
	justify-content: space-between;
}

.container .view_container {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.mobile-table {
	display: none;
}

@media (max-width: 680px) {
	.desktop-table {
		display: none;
	}
	.mobile-table {
		display: block;
	}
}
</style>
